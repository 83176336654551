import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import StaticPage from "../../common/StaticPage";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Alert } from '@mui/material';
import { useNavigate } from "react-router";
import Images from "../../request/components/Images";

export default function NoGoFeedback(props) {
  const navigate = useNavigate();

  const { reqId } = useParams();
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState("");
  const [reason, setReason] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  const alertRef = useRef(null);

  useEffect(() => {
    bkstApi(`/request/${reqId}/summary`).then((res) => {
      setData(res.data);
      setStatus("");
    });
  }, [reqId]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (reason) {
      const payload = {
        reason,
        text
      };
      bkstApi.post(`/request/${reqId}/feedback`, payload).then((res) => {
        setStatus("done");
      });
    } else {
      setError("reason");
      alertRef.current.scrollIntoView();
    }
  };

  if (status === "done") {
    return (
      <StaticPage title="Thank you!" desc="We appreciate you taking the time to help us improve" icon={CheckCircleOutlineOutlinedIcon}></StaticPage>
    );
  } else if (data) {
    return (
      <Container maxWidth="sm">
        <Box mt={6} mb={4} align="center">
          <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
            Help us improve
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary">
            What's the primary reason you didn't order from BakeStreet?
          </Typography>
        </Box>
        <Paper elevation={0} style={{ marginBottom: "1rem" }}>
          <Box px={2} py={2} ref={alertRef}>
            {error && (
              <Box mb={2}>
                <Alert severity="error">Please select an option</Alert>
              </Box>
            )}
            {data && data.cheapest && (
              <Box>
                <Grid container style={{ flexWrap: "noWrap" }}>
                  <Grid item>
                    <Images pics={data.pics} />
                  </Grid>
                  <Grid item>
                    <Box pl={2}>
                      <Typography variant="body1" gutterBottom>
                        {data.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >{`We sent you ${data.quotes} quotes starting from $${data.cheapest}`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={1}>
                  <Divider />
                </Box>
              </Box>
            )}
            <form onSubmit={onSubmit}>
              <Box my={2}>
                <RadioGroup name="reason" value={reason} onChange={(e) => setReason(e.target.value)}>
                  <FormControlLabel value="price" control={<Radio required={true} />} label="Found a cheaper option" />
                  <FormControlLabel value="fulfillment" control={<Radio required={true} />} label="Too far / No delivery option" />
                  <FormControlLabel value="trust" control={<Radio required={true} />} label="Not sure you're trustworthy" />
                  <FormControlLabel value="plan" control={<Radio required={true} />} label="No longer need a cake" />
                  <FormControlLabel value="quality" control={<Radio required={true} />} label="Didn't like any cake artist" />
                  <FormControlLabel value="other" control={<Radio required={true} />} label="Something else" />
                </RadioGroup>
              </Box>
              {reason && (
                <Box pb={2}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      value={text}
                      variant="outlined"
                      fullWidth
                      id="text"
                      label="Anything to add"
                      name="text"
                      multiline
                      rows={2}
                      required={reason === "other"}
                      onChange={(e) => setText(e.target.value)}
                      inputProps={{
                        "data-hj-allow": ""
                      }}
                    />
                  </FormControl>
                </Box>
              )}
              <Box align="right">
                <Button variant="contained" color="primary" type="submit">
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </Container>
    );
  } else {
    return <LinearProgress />;
  }
}
