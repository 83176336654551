import React, { useRef, useEffect } from "react";
import { Box, Button, Chip, Container, Hidden, Paper, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { STORAGE_BUCKET, REVIEWS, FEATURED_BAKERS } from "../common/constants";
import FeaturedReview from "../review/component/FeaturedReview";

const tags = [
  "3D",
  "abstract",
  "anniversary",
  "baptism",
  "boho",
  "cocomelon",
  "floral",
  "geode",
  "geometric",
  "ombre",
  "safari",
  "unicorn",
  "wedding"
];

export default function Home(props) {
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "BakeStreet: Book Insta-worthy cakes with talented cake artists";
    document.description =
      "Book your custom cakes with talented cake artists in your area. Search through 1000s of cake designs or upload your own to get quotes from trusted cake artists. Sit back & watch your quotes roll in. Compare price, rating & photos to pick the best option. Book your cake online. Work closely with your baker to bring your vision to life.";
  }, []);

  const cakeArtistCarousel = (
    <Carousel
      infiniteLoop={true}
      autoPlay={true}
      transitionTime={1000}
      stopOnHover={false}
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      showArrows={false}
      swipeable={false}
    >
      {FEATURED_BAKERS.map((o, idx) => {
        return (
          <Box key={idx} className="artist">
            <img alt="" src={`${STORAGE_BUCKET}/${o.img}`} />
            <Box className="img-title">
              <Typography variant="body1">Baker {o.name}</Typography>
            </Box>
          </Box>
        );
      })}
      <Box className="artist">
        <img alt="" src={`${STORAGE_BUCKET}/images/bg/artist/more3.png`} />
      </Box>
    </Carousel>
  );

  return (
    <div>
      <Grid id="hero" container alignItems="center">
        <Grid item sm={8} xs={12} alignItems="center">
          <Box my={5} align="center">
            <Box>
              <Typography variant="h1">
                Book <span style={{ color: "#78374a" }}>insta-worthy</span> cakes
              </Typography>
              <Typography variant="h1">with talented bakers</Typography>
              <Box mt={2}>
                <Typography variant="body1" color="textSecondary">
                  Million+ Designs &#9679; Trusted Bakers &#9679; Secure Payments
                </Typography>
              </Box>
              <Box my={5}>
                <Button
                  component={RouterLink}
                  variant="contained"
                  color="primary"
                  size="large"
                  to="/get-quote"
                  style={{ minHeight: "60px", minWidth: "220px", fontSize: "20px" }}
                >
                  GET STARTED
                </Button>
                <Box mt={2}>
                  <a target="_blank" href="https://www.trustpilot.com/review/bakestreet.co" style={{ textDecoration: "none" }}>
                    <img src={`${STORAGE_BUCKET}/img/h/TP.webp`} style={{ height: "35px" }} />
                  </a>
                </Box>
              </Box>
              <Hidden smUp>
                <Box px={10} mt={4}>
                  {cakeArtistCarousel}
                </Box>
              </Hidden>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Hidden smDown>
            <Box align="right">{cakeArtistCarousel}</Box>
          </Hidden>
        </Grid>
      </Grid>
      <Container>
        <Box py={18} align="center">
          <Box pb={7}>
            <Typography variant="h2">How it Works?</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item sm={4} xs={12}>
              <Box mb={4}>
                <img alt="" height="125" src={`${STORAGE_BUCKET}/images/page/home/design.svg`} />
                <Typography variant="subtitle1">Search 1000s of cake designs or upload your own to get quotes from talented cake artists.</Typography>
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box mb={4}>
                <img alt="" height="125" src={`${STORAGE_BUCKET}/images/page/home/compare2.svg`} />
                <Typography variant="subtitle1">
                  Sit back & watch your quotes roll in. Compare price, rating & photos to pick the best option.
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box mb={4}>
                <img alt="" height="125" src={`${STORAGE_BUCKET}/images/page/home/chat.svg`} />
                <Typography variant="subtitle1">Book your cake online. Work closely with your cake artist to bring your vision to life.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth={false} style={{ paddingTop: "7rem", paddingBottom: "10rem", backgroundColor: "#e7e7e7" }}>
        <Box align="center">
          <Container maxWidth="md">
            <Box pb={7}>
              <Typography variant="h2" gutterBottom>
                Top Notch Cakes & Treats
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Thousands of satisfied customers
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Paper elevation={0}>
                  <Box p={2} mb={2}>
                    <FeaturedReview data={REVIEWS[0]} />
                  </Box>
                </Paper>
                <Paper elevation={0}>
                  <Box p={2} mb={2}>
                    <FeaturedReview data={REVIEWS[1]} />
                  </Box>
                </Paper>
                <Paper elevation={0}>
                  <Box p={2} mb={2}>
                    <FeaturedReview data={REVIEWS[2]} />
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Paper elevation={0}>
                  <Box p={2} mb={2}>
                    <FeaturedReview data={REVIEWS[3]} />
                  </Box>
                </Paper>
                <Paper elevation={0}>
                  <Box p={2} mb={2}>
                    <FeaturedReview data={REVIEWS[4]} />
                  </Box>
                </Paper>
                <Paper elevation={0}>
                  <Box p={2} mb={2}>
                    <FeaturedReview data={REVIEWS[5]} />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Box mt={7}>
              <Button
                component={RouterLink}
                variant="contained"
                color="primary"
                size="large"
                to="/reviews"
                style={{ minHeight: "60px", minWidth: "220px", fontSize: "20px" }}
              >
                SEE MORE REVIEWS
              </Button>
            </Box>
          </Container>
        </Box>
      </Container>
      <Box py={14} style={{ backgroundColor: "#ceb4c1" }}>
        <Box align="center">
          <Box pb={4}>
            <Typography variant="h2">Popular Themes</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              What others are searching on BakeStreet
            </Typography>
          </Box>
          <Container maxWidth="md">
            {tags.map((o, idx) => {
              return (
                <Chip
                  key={idx}
                  color="primary"
                  variant="outlined"
                  label={o}
                  clickable
                  component={RouterLink}
                  to={`/collection/${o.replace(/\s/g, "-").toLowerCase()}-cakes`}
                />
              );
            })}
            <Box pt={7}>
              <Typography variant="subtitle1" color="textSecondary">
                Looking for something else?
              </Typography>
            </Box>
            <Box my={2}>
              <Button
                component={RouterLink}
                variant="contained"
                color="primary"
                size="large"
                to="/get-quote#occasion"
                style={{ minHeight: "60px", minWidth: "220px", fontSize: "20px" }}
              >
                SEARCH CAKE DESIGNS
              </Button>
            </Box>
          </Container>
        </Box>
      </Box>
      {/* <Container>
        <Box py={18} align="center">
          <Box pb={7}>
            <Typography variant="h2">Popular Collections</Typography>
          </Box>
          <Grid container spacing={3}>
            {collections.map((c) => {
              return (
                <Grid item sm={4} xs={6} key={c.title}>
                  <Link to={c.href} component={RouterLink} underline="none">
                    <Box p={1}>
                      <img alt="" style={{ width: "100%" }} src={c.src} />
                      <Typography variant="h4" color="textSecondary">
                        {c.title}
                      </Typography>
                    </Box>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
          </Container> */}
    </div>
  );
}
