import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { getQueryParam } from "../../../common/util";
import bkstApi from "../../../api";
import CloseIcon from "@mui/icons-material/Close";
import { BAKER_PIC_FOLDER } from "../../../common/constants";
import ChatCanvas from "../../../common/chat/ChatCanvas";
import NewMessage from "../../../common/chat/NewMessage";

export default function CustomerChat(props) {
  const ref = useRef(null);

  const [open, setOpen] = useState(getQueryParam(window.location.search, "to") === props.quoteId || window.location.hash.includes("chat"));
  const [chat, setChat] = useState(props.chat || []);
  const [showTooltip, setShowTooltip] = useState(props.showTooltip);

  useEffect(() => {
    if (open) {
      bkstApi(`/request/${props.reqId}/quote/${props.quoteId}/chat`).then((res) => {
        setChat(res.data.chat || []);
      });
      setShowTooltip(false);

      // scroll to quote
      const el = document.getElementById(`quote-${props.quoteId}`);
      if (el) {
        window.requestAnimationFrame(() => el.scrollIntoView());
      }
    }
  }, [open]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [chat]);

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  return (
    <Box display="inline">
      <Tooltip open={showTooltip} title="Ask a question!" placement="top" arrow>
        <Button size="small" variant={props.variant || "outlined"} color={props.color || "secondary"} onClick={(e) => setOpen(true)}>
          Contact
        </Button>
      </Tooltip>
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ margin: "0", paddingLeft: "10px", paddingRight: "5px" }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
              <Avatar src={`${BAKER_PIC_FOLDER}/${props.quoteId}/photo400.webp`} sx={{ width: "32px", height: "32px" }} />
              {props.name}
            </Box>
            <Box>
              <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex", flexDirection: "column" }}>
          <Box mb={1} sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <ChatCanvas chat={chat} />
            <Box textAlign="center" ref={ref}>
              <Chip label="VIEW QUOTE" color="primary" onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: 0, border: 0, paddingBottom: "10px", paddingRight: "10px", paddingLeft: 0, paddingTop: 0 }}>
          <Box sx={{ flexGrow: "1" }}>
            <NewMessage
              handleNewMsg={handleNewMsg}
              uploadFolder={`img/r/${props.reqId}`}
              postUrl={`/requests/${props.reqId}/quotes/${props.quoteId}/chat`}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
