import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Grow, Paper, Slide, Typography } from "@mui/material";
import Images from "../Images";
import Question from "./Question";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import SearchDesigns from "./SearchDesigns";
import Themes from "./Themes";

const designOptions = [
  { title: "search", icon: "search.svg" },
  { title: "upload", icon: "upload.svg" }
];

const occasions = [
  { title: "birthday", icon: "birthday.svg" },
  { title: "baby shower", icon: "teddy.svg" },
  { title: "wedding", icon: "wedding.svg" },
  { title: "anniversary", icon: "anniversary.svg" }
];

const birthdayOptions = [
  { title: "baby", icon: "infant.svg" },
  { title: "small kid", icon: "toddler.svg" },
  { title: "teen", icon: "bigkid.svg" },
  { title: "adult", icon: "adult.svg" }
];

const tagsDb = {
  wedding: ["abstract", "bohemian", "floral", "geode", "ombre", "semi naked", "textured"],
  graduation: ["book", "doctor", "lawyer", "striped", "quilted", "dentist"],
  "baby-shower": ["angel", "balloon", "booties", "celestial", "cloud", "floral", "gender reveal", "stork"],
  anniversary: ["boho", "fashion", "floral", "heart", "liquor", "textured", "travel"],
  baptism: [],
  business: ["doctor", "lawyer", "police", "dj", "camera", "computer"]
};

export default function Design(props) {
  const ref = useRef(null);

  const navigate = useNavigate();
  let location = useLocation();

  const [step, setStep] = useState("");

  const [occasion, setOccasion] = useState("");
  const [lifestage, setLifestage] = useState("");
  const [tags, setTags] = useState();

  const [pics, setPics] = useState([]);

  useEffect(() => {
    if (!location.hash || location.hash === "#design") {
      setStep("design");
      //ref.current.scrollIntoView();
    } else if (location.hash === "#occasion") {
      setStep("occasion");
      ref.current.scrollIntoView();
    } else if (location.hash === "#lifestage") {
      setStep("lifestage");
      ref.current.scrollIntoView();
    } else if (location.hash.startsWith("#theme/")) {
      setStep("theme");
      ref.current.scrollIntoView();
    } else if (location.hash.startsWith("#search")) {
      setStep("search");
      ref.current.scrollIntoView();
    } else if (location.hash === "#upload") {
      setStep("upload");
      ref.current.scrollIntoView();
    }
  }, [location.hash]);

  const onSubmit = (keyword, photos) => {
    setPics(photos);
    props.onSubmit({ occasion, lifestage, pics: photos, keyword });
  };

  const handleLifestage = (value) => {
    setLifestage(value);
    navigate(`#theme/${value.replace(/\s/g, "")}`);
  };

  const handleTheme = (o) => {
    setTags(o.tags);
    navigate(`#search/${o.keyword.replace(/\s/g, "-")}`);
  };

  const handleOccasion = (value) => {
    setOccasion(value);
    if (value === "birthday") {
      navigate("#lifestage");
    } else if (value === "other") {
      navigate(`#theme/other`);
    } else if (value === "anniversary") {
      navigate(`#theme/anniversary`);
    } else {
      const token = value.replace(/\s/g, "-");
      setTags(tagsDb[token]);
      navigate(`#search/${token}`);
    }
  };

  const handleDesign = (value) => {
    if (value === "search") {
      navigate(`#occasion`);
    } else {
      navigate(`#upload`);
    }
  };

  return (
    <Box align="center" ref={ref}>
      {step === "occasion" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Question
              title="What are you celebrating?"
              options={occasions}
              otherOptions={["bachelorette", "baptism", "business", "job", "graduation", "other"]}
              handleClick={handleOccasion}
            />
          </Box>
        </Grow>
      )}
      {step === "design" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Question
              title="Let's pick a design"
              subtitle="Search through 1000s of popular designs or upload your own design"
              options={designOptions}
              handleClick={handleDesign}
            />
          </Box>
        </Grow>
      )}
      {step === "lifestage" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Question title="Who's birthday is it?" options={birthdayOptions} handleClick={handleLifestage} />
          </Box>
        </Grow>
      )}
      {step === "theme" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Themes onSubmit={handleTheme} />
          </Box>
        </Grow>
      )}
      {step === "upload" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Paper>
            <Box px={2} py={4}>
              <Typography variant="h4" gutterBottom>
                Upload Design
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Click below to add upto 5 photos
              </Typography>
              <Box py={3}>
                <Grid container>
                  <Grid item xs={3} sm={4}></Grid>
                  <Grid item xs={6} sm={4}>
                    <Images pics={pics} edit={true} onChange={(images) => setPics(images)} folder={`img/r`} limit={5} />
                  </Grid>
                  <Grid item xs={3} sm={4}></Grid>
                </Grid>
              </Box>
              <Box align="center">
                <Button color="primary" variant="contained" onClick={() => onSubmit("", pics)} disabled={pics.length === 0}>
                  Continue
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grow>
      )}
      {step === "search" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <SearchDesigns tags={tags} occasion={occasion} onSubmit={onSubmit} />
          </Box>
        </Grow>
      )}
    </Box>
  );
}
