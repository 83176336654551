import React, { useState } from "react";
import { IconButton, CircularProgress } from "@mui/material";
import { upload } from "../../firebase";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

export default function AttachPhoto(props) {
  const [working, setWorking] = useState(false);

  async function handleUpload(event, folder, handleAttachments) {
    let tasks = [];
    setWorking(true);

    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      tasks.push(
        new Promise(async (resolve, reject) => {
          upload(file, folder).then((snapshot) => {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              resolve(downloadURL);
            });
          });
        })
      );
    }

    const imageUrls = await Promise.all(tasks);
    console.log("ImageUrls", imageUrls);
    handleAttachments(imageUrls);
    setWorking(false);
  }

  const inputComponent = (
    <input
      accept="image/*"
      id={props.id}
      type="file"
      multiple
      onChange={(e) => handleUpload(e, props.folder, props.handleAttachments)}
      style={{ display: "none" }}
    />
  );

  return (
    <IconButton component="label" size="small">
      {working ? <CircularProgress size={18} /> : <AttachFileOutlinedIcon size={18}/>}
      {inputComponent}
    </IconButton>
  );
}
