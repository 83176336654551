import React from "react";
import { Box, Typography } from "@mui/material";
import Rating from '@mui/material/Rating';
import { STORAGE_BUCKET } from "../../common/constants";

export default function Review(props) {
  return (
    <Box>
      <Box py={1}>
          <img
            src={`${STORAGE_BUCKET}${props.data.img}`}
            style={{ width: "100%", height: "auto" }}
            alt=""
          />
      </Box>
      <Box px={2} mt={2}>
        <Rating precision={0.25} name="rating" value={Number(props.data.rating)} readOnly size="large" />
        <Typography variant="body1" style={{ textAlign: "justify" }} gutterBottom>
          {props.data.text}
        </Typography>
        <Box align="left">
        <Typography variant="overline" color="textSecondary">{props.data.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
