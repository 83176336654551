import React from "react";
import { Box, Typography } from "@mui/material";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";

export default function OrderCareInstructions(props) {
  return (
    <Box>
      <Typography variant="overline" color="textSecondary">
        Care Instructions
      </Typography>
      <Box sx={{ border: "1px solid #DDD" }}>
        <Box p={2} sx={{ display: "flex", gap: "15px" }}>
          <Box sx={{ fontSize: "60px", color: "#777" }}>
            <VisibilityOutlinedIcon fontSize="inherit" />
          </Box>
          <Box>
            <Typography variant="h6">Inspect</Typography>
            <Typography variant="body2" color="textSecondary">
              Inspect your order at pickup/delivery to ensure everything is in order. Your cake may contain hidden, inedible items like dowels, metal
              & styrofoam for decor & support. Be sure to remove them before serving.
            </Typography>
          </Box>
        </Box>
        <Box p={2} sx={{ display: "flex", gap: "15px" }}>
          <Box sx={{ fontSize: "60px", color: "#777" }}>
            <VolunteerActivismOutlinedIcon fontSize="inherit" />
          </Box>
          <Box>
            <Typography variant="h6">Handle with Care</Typography>
            <Typography variant="body2" color="textSecondary">
              Carry your order with both hands at the bottom of the box. Keep the box level at all times. Transport on a flat surface in your vehicle,
              like the front floor board or the trunk.
            </Typography>
          </Box>
        </Box>
        <Box p={2} sx={{ display: "flex", gap: "15px" }}>
          <Box sx={{ fontSize: "60px", color: "#777" }}>
            <AcUnitOutlinedIcon fontSize="inherit" />
          </Box>
          <Box>
            <Typography variant="h6">Keep it Cool</Typography>
            <Typography variant="body2" color="textSecondary">
              Keep away from sunlight, heat & moisture. Transport in an air-conditioned vehicle. Store in a refrigerator. Best served
              at room temperature.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
