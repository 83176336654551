import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import LazyLoad from "react-lazy-load";
import ExpandableImage from "./ExpandableImage";

export default function Product(props) {
  const [loading, setLoading] = useState(true);

  return (
    <Paper elevation={3} style={{ backgroundColor: "#eee", padding: "5px", marginBottom: "10px" }}>
      {loading && <Skeleton variant="rectangular" width="100%" height={350} />}
      <LazyLoad
        offset={600}
        onContentVisible={() => {
          setLoading(false);
        }}
        debounce={false}
      >
        <Box>
          <ExpandableImage
            src={props.img}
            style={window.screen.width <= 480 ? { height: "auto", width: "100%" } : { height: "250px", width: "100%", objectFit: "cover" }}
          />
          {props.children}
        </Box>
      </LazyLoad>
    </Paper>
  );
}
