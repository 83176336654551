import React, { useEffect, useState } from "react";
import { getQueryParam } from "../common/util";
import { auth } from "../firebase";
import bkstApi from "../api";
import { useNavigate } from "react-router";

export const UserContext = React.createContext();

export default ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");

  // check utm params
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        me();
      }
    });
    sessionStorage.setItem("landing", window.location.pathname);

    const utms = getQueryParam(window.location.search, "utm_source");
    if (utms) {
      const utmm = getQueryParam(window.location.search, "utm_medium");
      const utmc = getQueryParam(window.location.search, "utm_campaign");

      sessionStorage.setItem("utm", JSON.stringify({ utms, utmm, utmc }));
    } else {
      var referrer = document.referrer;
      if (referrer) {
        var a = document.createElement("a");
        a.href = referrer;

        sessionStorage.setItem("utm", JSON.stringify({ utms: a.hostname, utmm: "", utmc: "" }));
      }
    }
  }, []);

  const me = () => {
    bkstApi(`/me`).then((res) => {
      const user = res.data.user;
      if (user) {
        setUser(user);

        const target = getQueryParam(window.location.search, "target");
        if (user.profile.status === "pending" || user.profile.status === "quote") {
          window.location.replace('https://my.bakestreet.co/onboarding');
        } else if (target) {
          navigate(target);
        } else if (window.location.pathname === "/" || window.location.pathname === "/login") {
          window.location.replace('https://my.bakestreet.co');
        }
      }
    });
  };

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
