import React from "react";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placeDetailService = { current: null };

export default function PlacesAutocomplete(props) {
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAyx61Vexi1R7053yGLLtczRbjMXi6O6_Q&libraries=places&language=en",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        request.types = ["(regions)"];
        request.componentRestrictions = { country: "us" };
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const getPlaceDetails = (placeId) => {
    placeDetailService.current.getDetails({ placeId }, (place, status) => {
      const ct = place.address_components.find((item) => item.types.includes("locality") || item.types.includes("sublocality") || item.types.includes("neighborhood"));
      const st = place.address_components.find((item) => item.types.includes("administrative_area_level_1"));
      const zp = place.address_components.find((item) => item.types.includes("postal_code"));
      const coord = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };

      let location = { placeId, coord, description: place.formatted_address };
      if (ct) {
        location.city = ct.long_name;
      }
      if (st) {
        location.state = st.short_name;
      }
      if (zp) {
        location.zip = zp.short_name;
      }
      props.onChange(location);
    });
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placeDetailService.current = new window.google.maps.places.PlacesService(document.getElementById("google-maps"));
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        /*if (value) {
          newOptions = [value];
        }*/

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="places-autocomplete"
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description.replace(", USA", ""))}
      options={options}
      noOptionsText=""
      disableClearable
      includeInputInList
      selectOnFocus
      value={value}
      onClose={(event, reason) => {
        if (reason === "blur") {
          if (options && options.length > 0) {
            setValue(options[0]);
            getPlaceDetails(options[0].place_id);
          }
        }
      }}
      onChange={(event, newValue, reason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        getPlaceDetails(newValue.place_id);
        event.target.blur();
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          inputProps={{
            "data-hj-allow": ""
          }}
          {...params}
          label={props.label || "Zip Code"}
          variant="outlined"
          helperText={props.helperText}
          error={props.error}
          fullWidth
          required={props.required}
          autoFocus={props.autoFocus || false}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: "text.secondary", mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
