import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CXL_POLICY } from "../common/constants";

export default function CustomerFaq(props) {
  return (
    <Box>
      <Box my={5}>
        <Typography variant="h5" gutterBottom>
          Getting Started
        </Typography>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What is BakeStreet?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Bakestreet is a marketplace that connects you with top cake artists. We help you find the perfect cake design for your event based on
              classic & trending themes, designs & your preferences. We share your cake design & other details (event date, servings, etc) with top
              cake artists & request quotes from them. We'll notify you as quotes come in, so you can review & compare all options at your
              convenience. When you're ready to order, simply pick the quote that works best for you & pay online. We'll work with you & your baker to
              ensure you have a fantastic experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Why can't I do this on my own?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Sure you can, but it may take a lot of time & effort. For starters, its really hard to find top bakers in your area. You may need to use
              Yelp, Facebook, Instagram and ask friends for references. Each baker has their preffered mode of communication (facebook, email, phone).
              Many bakers respond only during business hours when you're probably busy too. There's usually a lot of back & forth communication before
              you can get a quote. Top bakers tend to be very busy & are usually booked many weeks or even months in advance, so it can be very
              challenging if you're short on time. Finally, most bakers don't offer a way to order & pay online and if something goes wrong with your
              order, there's no one to mediate on your behalf.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box my={5}>
        <Typography variant="h5" gutterBottom>
          Quotes
        </Typography>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How do I see my quotes?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              We'll send you an email & text with a link to your personalized quotes as soon as your quotes start coming in. It usually takes a few
              hours, but may take as much as 24 hours in some cases. When you click on your personalized link, you'll see a list of bakers we
              contacted for quotes on your behalf. You'll be able to see bakers that responded with a quote, bakers that are unavailable for your date
              & bakers who haven't responded yet. You'll also be able to see each baker's rating & pictures of their past work.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Should I wait for all quotes to come in?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Most bakers respond within 24 hours & quotes expire shortly after. Some bakers may not respond at all if they're busy or booked. Top
              bakers in your area have limited availability & get booked pretty fast. If you like a baker, their work & their quote, we recommend
              placing an order within 24 hours to ensure you don't miss out.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Why are some quotes so expensive?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Custom baked goods like cakes are an art form and there's no standard pricing. Each cake artist brings their own unique talent, skill &
              experience to create a one-of-a-kind experience for you & that is reflected in their pricing.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box my={5}>
        <Typography variant="h5" gutterBottom>
          Orders
        </Typography>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What happens after I place an order?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Once you order & pay online, we'll contact your baker to confirm your order. Then we'll send you an Order Confirmation email with all
              relevant details & connect you directly with your baker. Please be sure to include us in any conversation so we can mediate & make
              things right if there's a problem.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How do I communicate with my baker?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Once the order is placed, we'll connect you with your baker. You can then communicate with them to finalize customization details like
              flavor, greetings, etc. Be sure to keep us in the loop, so we can ensure everyone is perfectly aligned & there's no confusion.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Can I change my order?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Most bakers are happy to accommodate minor changes like color, flavor, toppers, greetings, etc. Last minute changes & design changes
              that are more involved may require additional charges & depend on your baker's discretion. Please contact your baker to discuss your
              changes. Our bakers take pride in their work & always aim to exceed your expectations.
              <br />
              <br />
              If you wish to change your order date, please take a moment to read through the order date-change policy & email us at
              support@bakestreet.co for assistance.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Can I cancel my order?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Custom baked goods are hand-made to your unique instructions, so they can't be used elsewhere. When you place an order, your baker
              blocks part of their day to work on your order and don't accept any more orders for that time. When you cancel your order, your baker
              may not be able to find another customer and they lose business. <br />
              <br />
              Work on your cake typically begins days or even weeks in advance. Depending on your cake, the baker may need to order specific baking
              supplies they may not have on hand. To ensure that we're doing right by you as well as your baker, we have a standard cancellation
              policy that applies to all orders.
              <br />
              <br />
              If you wish to cancel your order, please take a moment to read through the cancellation policy & email us at support@bakestreet.co for
              assistance.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box my={5}>
        <Typography variant="h5" gutterBottom>
          Payment
        </Typography>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Are my payment details secure?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Your credit card is processed by a third-party specializing in online payments. They are an industry leader, PCI compliant, safe, secure
              & reliable way for online payments. We can't see or store your credit card information and your payment details are 100% secure.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box my={5}>
        <Typography variant="h5" gutterBottom>
          Feedback
        </Typography>
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>The cake didn't meet my expectations. Can you help?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Sorry, things didn't work out! We know how frustrating that can be. We're committed to exceeding your expectations & we'll do our best
              to make it right. Please email us at support@bakestreet.co with your details and we'll get back to you as soon as possible.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
