import React from "react";
import { Avatar, Box, Grid, Link, Typography } from "@mui/material";
import { BAKER_PIC_FOLDER } from "../../common/constants";
import OrderChat from "./OrderChat";

export default function BakerContactCard(props) {
  const { order } = props;

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Avatar alt="" src={`${BAKER_PIC_FOLDER}/${order.baker.id}/${order.baker.photo}`} style={{ width: "75px", height: "75px" }} />
        </Grid>
        <Grid item>
          <Box style={{ textAlign: "left" }}>
            <Typography variant="h6" gutterBottom>
              {order.baker.name}
            </Typography>
            <OrderChat order={order} fullWidth />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
