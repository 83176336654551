import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ExpandableImage(props) {
  const [open, setOpen] = useState(false);

  let style = Object.assign({ cursor: "pointer" }, props.style || { height: "40px", width: "40px", objectFit: "cover" });

  return (
    <span>
      <img alt="" src={props.src} style={style} onClick={() => setOpen(true)} />
      <Dialog fullScreen scroll="paper" open={open} onClose={() => setOpen(false)} onClick={() => setOpen(false)}>
        <DialogTitle style={{ backgroundColor: "#000", color: "#fff", margin: "0", border: 0 }}>
          <Box align="left">
            <Button color="inherit" size="small" onClick={() => setOpen(false)}>
              <CloseIcon /> CLOSE
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center" }}>
          <Box align="center">
            <img alt="" src={props.src} style={{ width: "100%" }}/>
          </Box>
        </DialogContent>
      </Dialog>
    </span>
  );
}
export default ExpandableImage;
