import React, { useEffect, useRef } from "react";
import { Box, Button, Dialog, DialogContent, Grid, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

const GOOGLE_MAP_API_KEY = "AIzaSyAyx61Vexi1R7053yGLLtczRbjMXi6O6_Q";

export default function BakerApproxLocation(props) {
  const googleMapRef = React.createRef();
  const googleMap = useRef(null);
  const [open, setOpen] = React.useState(false);

  const createGoogleMap = () => {
    const map = new window.google.maps.Map(googleMapRef.current, {
      zoom: 13,
      center: {
        lat: props.location.coord.lat,
        lng: props.location.coord.lng
      },
      streetViewControl: false
    });

    new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: "#FF0000",
      fillOpacity: 0.2,
      map,
      center: { lat: props.location.coord.lat, lng: props.location.coord.lng },
      radius: 1000
    });

    return map;
  };

  useEffect(() => {
    if (open) {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener("load", () => {
        googleMap.current = createGoogleMap();
      });
    }
  }, [open]);

  let city = props.location.city;
  if (city.length > 15 && city.includes(",")) {
    city = city.split(",").shift();
  }

  return (
    <Box>
      <Grid container style={{ flexWrap: "noWrap" }}>
        <Grid item>
          <PinDropOutlinedIcon style={{ fontSize: "1.2rem", marginRight: ".4rem" }} />
        </Grid>
        <Grid item>
          <Typography variant="body2" display="inline">
            {`${city}, ${props.location.state} `}{" "}
          </Typography>
          {props.distance && (
            <Typography variant="body2" display="inline">
              {`(${props.distance} mi)`}{" "}
            </Typography>
          )}
          {props.distance && (
            <Link underline="always" onClick={() => setOpen(true)} style={{ cursor: "pointer", color: "inherit" }}>
              map
            </Link>
          )}
        </Grid>
      </Grid>
      {open && (
        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
          <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center" }}>
            <Box mb={2} align="right">
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={8} align="left">
                  <Typography variant="body1">Exact location provided after booking</Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Button size="large" variant="outlined" color="inherit" onClick={() => setOpen(false)}>
                    CLOSE <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <div id="google-map" ref={googleMapRef} style={{ width: "100%", height: "90%" }} />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
