import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import bkstApi from "../api";

export default function DynamicPage(props) {
  const [page, setPage] = useState("");

  useEffect(() => {
    bkstApi(`/p/${props.handle}`)
      .then((res) => {
        setPage(res.data);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [props.handle]);

  return (
    <Container maxWidth="md">
      <Box pt={10} pb={3} align="center">
        <Box>
          <Typography variant="h1">
            {page.title}
          </Typography>
          <Typography variant="h4" style={{ color: "#eee" }}>
            {page.subtitle}
          </Typography>
        </Box>
        <Box align="left" my={7}><div dangerouslySetInnerHTML={{ __html: page.content }} /></Box>
      </Box>
    </Container>
  );
}
