import React from "react";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";

export default function InfoPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <span>
      <InfoIcon onClick={handlePopoverOpen} style={{ fontSize: "1rem", verticalAlign: "middle" }} />
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {props.text}
      </Popover>
    </span>
  );
}
