import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import BakerReviewsPopup from "./BakerReviewsPopup";

export default function BakerReviewButton(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      props.addEvent && props.addEvent("rvc");
    }
  }, [open]);

  return (
    <span>
      <Button size="small" variant="text" color="primary" onClick={() => setOpen(true)}>
        Reviews
      </Button>
      <BakerReviewsPopup name={props.name} reviews={props.reviews} baker={{ id: props.bakerId }} open={open} onClose={() => setOpen(false)} />
    </span>
  );
}
