import { Box, Chip, Paper, Typography } from "@mui/material";
import Option from "../Option";

export default function Question(props) {
  return (
    <Paper>
      <Box px={2} py={4}>
        <Box mb={4}>
          <Typography variant="h3" gutterBottom>{props.title}</Typography>
          <Typography variant="subtitle1" color="textSecondary">{props.subtitle}</Typography>
        </Box>
        <Box my={3}>
          {props.options.map((o, idx) => {
            return <Option key={idx} title={o.title} icon={o.icon} onClick={() => props.handleClick(o.title)} />;
          })}
        </Box>
        {props.otherOptions && (
          <Box>
            <Typography variant="overline" color="textSecondary" display="block" gutterBottom>
              Other options
            </Typography>
            {props.otherOptions.map((o, idx) => {
              return <Chip key={idx} size="medium" color="primary" variant="outlined" label={o} clickable onClick={() => props.handleClick(o)} />;
            })}
          </Box>
        )}
      </Box>
    </Paper>
  );
}
