import React, { useState } from "react";
import { Box, Container, TextField, Typography } from "@mui/material";
import bkstApi from "../api";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { useNavigate } from "react-router";

export default function Verify(props) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const verifyCode = (code) => {
    if (code.length < 4) {
      setError(false);
    } else if (code.length === 4) {
      bkstApi.put(`/request/${props.reqId}/verify-code`, { code }).then((res) => {
        if (res.data.status === "verified") {
          navigate(`/requests/${props.reqId}`);
        } else {
          setError(true);
        }
      });
    } else if (code.length > 4) {
      setError(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box px={3} py={5} my={5} align="center">
        <PhoneAndroidOutlinedIcon style={{ fontSize: "5rem" }} />
        <Typography variant="subtitle1">Enter the verification code</Typography>
        <Typography color="textSecondary">{`We sent a text message with a verification code to ${props.phone}`}</Typography>
        <Box mt={4}>
          <TextField
            inputProps={{ style: { width: "16rem", textAlign: "center", fontSize: "4rem", letterSpacing: "1rem" } }}
            error={error}
            variant="filled"
            type="number"
            id="code"
            name="code"
            required
            autoFocus
            onChange={(e) => verifyCode(e.target.value)}
            helperText={error ? "Incorrect code" : ""}
          />
        </Box>
      </Box>
    </Container>
  );
}
