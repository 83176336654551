import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@mui/material";
import BakerFlavors from "../../baker/screen/BakerFlavors";
import bkstApi from "../../api";

export default function BakerFlavorsPopup(props) {
  const [open, setOpen] = useState(false);
  const [flavors, setFlavors] = useState("");
  const [fillings, setFillings] = useState("");

  useEffect(() => {
    if (open) {
      props.addEvent && props.addEvent("flc");

      bkstApi(`/baker/${props.bakerId}/flavors`).then((res) => {
        setFlavors(res.data.flavors);
        setFillings(res.data.fillings);
      });
    }
  }, [open]);

  return (
    <Box display="inline">
      <Button size="small" color={props.color || "secondary"} variant={props.variant || "outlined"} onClick={() => setOpen(true)}>
        Flavors
      </Button>
      <Dialog open={open} onClose={props.onClose} scroll="paper" fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <Typography>{props.name}'s Flavor List</Typography>
        </DialogTitle>
        <DialogContent style={{ height: "90vh" }}>
          <Box p={1}>
            <BakerFlavors flavors={flavors} fillings={fillings} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
