import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Button, Container, FormControl, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import ReviewRatingQuestion from "../component/ReviewRatingQuestion";
import StaticPage from "../../common/StaticPage";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import OrderReviewNPS from "./OrderReviewNPS";

export default function OrderReview(props) {
  const { orderId } = useParams();
  const [status, setStatus] = useState("loading");
  const [baker, setBaker] = useState();
  const [decoration, setDecoration] = useState(0);
  const [taste, setTaste] = useState(0);
  const [communication, setCommunication] = useState(0);
  const [fulfillment, setFulfillment] = useState(0);
  const [text, setText] = useState("");

  useEffect(() => {
    bkstApi(`/order/${orderId}/review`).then((res) => {
      if (res.data.baker) {
        setBaker(res.data.baker);
        setStatus("new");
      } else {
        setStatus(res.data.status);
      }
    });
  }, [orderId]);

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      orderId,
      decoration,
      taste,
      communication,
      fulfillment,
      text
    };
    bkstApi.post(`/baker/${baker.id}/review`, payload).then((res) => {
      
    });

    setStatus("nps");
  };

  if (status === "loading") {
    return <LinearProgress />;
  } else if (status === "closed") {
    return <StaticPage title="Closed" desc="Sorry, this page is no longer available." icon={UpdateOutlinedIcon}></StaticPage>;
  } else if (status === "nps") {
    return <OrderReviewNPS orderId={orderId} setStatus={setStatus} />;
  } else if (status === "done") {
    return (
      <StaticPage
        title="Thank you!"
        desc="We appreciate you taking the time to share your experience"
        icon={CheckCircleOutlineOutlinedIcon}
      ></StaticPage>
    );
  } else {
    return (
      <Container maxWidth="sm">
        <Box pt={2} align="center">
          <Avatar src={baker.pic} alt="" style={{ width: "75px", height: "75px" }} />
          <Typography variant="overline" color="textSecondary">
            {baker.name}
          </Typography>
        </Box>
        <form onSubmit={onSubmit}>
          <Box my={2} align="center">
            <Typography variant="h5" gutterBottom>
              Review your Cake Artist
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Share what you loved and what could be better
            </Typography>
          </Box>
          <Box>
            <ReviewRatingQuestion heading="Decoration" subtitle="Was the cake beautiful?" onChange={(e) => setDecoration(e.target.value)} />
            <ReviewRatingQuestion
              heading="Taste"
              subtitle="Did you like the flavor, filling & frosting?"
              onChange={(e) => setTaste(e.target.value)}
            />
            <ReviewRatingQuestion
              heading="Communication"
              subtitle="Were your queries answered in a timely manner?"
              onChange={(e) => setCommunication(e.target.value)}
            />
            <ReviewRatingQuestion
              heading="Pickup/Delivery"
              subtitle="Was the pickup/delivery easy & on-time?"
              onChange={(e) => setFulfillment(e.target.value)}
            />
          </Box>
          <Paper elevation={0} style={{ marginBottom: "1rem" }}>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Write a public review
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Your first-hand experience goes a long way to help others pick the best cake artist
              </Typography>
              <Box py={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={text}
                    variant="outlined"
                    fullWidth
                    id="text"
                    label="What you loved & what could be better?"
                    name="text"
                    multiline
                    required
                    rows={4}
                    onChange={(e) => setText(e.target.value)}
                    inputProps={{
                      "data-hj-allow": ""
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Paper>
          <Box mt={3} align="right">
            <Button type="submit" variant="contained" color="primary">
              SUBMIT
            </Button>
          </Box>
        </form>
      </Container>
    );
  }
}
