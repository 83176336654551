import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { STORAGE_BUCKET } from "../../common/constants";

export default function RequestExpired(props) {
  const navigate = useNavigate();
  const { reqId } = useParams();

  const reopen = () => {
    bkstApi.put(`/requests/${reqId}/ask-to-reopen`, {}).then((res) => {
      navigate(`/requests/${reqId}`);
      window.location.reload();
    });
  };

  return (
    <Container maxWidth="sm">
      <Box align="center">
        <Box my={4}>
          <img alt="" height="125" src={`${STORAGE_BUCKET}/images/page/home/time.svg`} />
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            All your quotes have expired
          </Typography>
        </Box>
        <Paper elevation={0} style={{ backgroundColor: "#EEE" }}>
          <Box px={3} py={6} align="left">
            <Typography variant="h4" gutterBottom>
              Still looking?
            </Typography>
            <Typography variant="body1" color="textSecondary">
              View your original quotes & contact your cake artist to see if they are still available.
            </Typography>
            <Box mt={2} align="right">
              <Button size="large" variant="contained" color="primary" onClick={reopen} fullWidth>
                Show My Quotes
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
