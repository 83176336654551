import React, { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link as RouterLink } from "react-router-dom";

export default function StripePaymentElement(props) {
  const { amount, disabled, validate, returnUrl, showPolicy } = props;

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (validate()) {
      setProcessing(true);

      stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: returnUrl
          }
        })
        .then(function (result) {
          if (result.error) {
            setError(result.error.message);
            setProcessing(false);
          }
        });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          paymentMethodOrder: ["apple_pay", "google_pay", "card"]
        }}
      />
      <Box align="center" my={1}>
        {/* Show any error that happens when processing the payment */}
        {error && <Typography color="error">{error}</Typography>}
      </Box>
      {showPolicy && (
        <Box my={2}>
          <Typography variant="caption" color="textSecondary">
            By selecting the button below, I agree to the{" "}
            <Link underline="always" href="#cancelation-policy">
              Cancelation Policy
            </Link>
            ,{" "}
            <Link underline="always" href="#late-payment-policy">
              Late Payment Policy
            </Link>
            ,{" "}
            <Link underline="always" href="#refund-policy">
              Refund Policy
            </Link>
            ,{" "}
            <Link underline="always" component={RouterLink} to="/terms-of-service">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link underline="always" component={RouterLink} to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
      )}
      <Button size="large" type="submit" color="primary" variant="contained" fullWidth disabled={processing || succeeded || disabled}>
        {`Pay ${amount}`}
      </Button>
      {processing && (
        <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </form>
  );
}
