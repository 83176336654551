import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Images from "./Images";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import IconText from "../../common/IconText";
import moment from "moment";
import TextWithReadMore from "../../common/TextWithReadMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { getDisplayName } from "../../common/util";

export default function RequestSummary(props) {
  const { request } = props;
  return (
    <Paper elevation={0} style={{ backgroundColor: "#ECECEC" }}>
      <Box fontSize={16} px={2} py={3}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={5}>
            <Images
              showArrows={true}
              pics={props.request.pics || [props.request.pic1, props.request.pic2, props.request.pic3].filter((i) => i)}
            />
          </Grid>
          <Grid item sm={8} xs={7}>
            <Box mb={1}>
              <Typography variant="h6" display="inline">
                {props.request.title}
              </Typography>
            </Box>
            <Box style={{ color: "#777" }}>
              <IconText icon={PersonOutlineIcon} text={getDisplayName(request.firstName, request.lastName, request.name)} />
              <IconText
                icon={EventOutlinedIcon}
                text={`${moment(props.request.date).format("ddd, MMM DD, YYYY")} ${props.request.time ? `[${props.request.time}]` : ""} `}
              />
              <IconText icon={CakeOutlinedIcon} text={`${props.request.servings} servings`} />
              {props.request.location && <IconText icon={PinDropOutlinedIcon} text={props.request.location} />}
              {props.request.budget && <IconText icon={LocalAtmOutlinedIcon} text={`$${props.request.budget} Budget`} />}
            </Box>
          </Grid>
          {props.request.details && props.request.details.length > 2 && (
            <Grid item sm={12} xs={12}>
              <Box>
                <TextWithReadMore data={props.request.details} limit={140} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
