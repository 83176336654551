import { useState, useEffect, useCallback } from "react";
import { Box, Button, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import BDatePicker from "../../../common/input/BDatePicker";
import PlacesAutocomplete from "../../../common/PlacesAutocomplete";
import bkstApi from "../../../api";
import { Alert, AlertTitle } from '@mui/material';
import debounce from "lodash.debounce";

export default function Event(props) {
  const [open, setOpen] = useState(false);

  const [low, setLow] = useState("");
  const [mid, setMid] = useState("");
  const [high, setHigh] = useState("");
  const [guide, setGuide] = useState("");

  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [servings, setServings] = useState("");
  const [budget, setBudget] = useState("");

  const [bakers, setBakers] = useState();

  useEffect(() => {
    if (location) {
      setBakers();
      bkstApi(`/bakers?state=${location.state}&lat=${location.coord.lat}&lng=${location.coord.lng}`).then((res) => {
        setBakers(res.data);
      });
    }
  }, [location]);

  const onLocationChange = (value) => {
    setLocation(value);
    document.activeElement.blur();
  };

  const evalBudgetGuide = useCallback(
    debounce((budget, low, mid, high) => {
      if (budget) {
        if (budget < low) {
          setGuide(`Low budget! You are unlikely to get any quotes. Please consider raising your budget to $${low}+.`);
        } else if (budget < mid) {
          setGuide(
            `Decent budget! You can expect 1-3 quotes from upcoming cake artists. To get quotes from seasoned cake artists with a ton of experience, consider raising your budget to $${mid}+.`
          );
        } else if (budget < high) {
          setGuide(
            `Good budget! You can expect 3-5 quotes from seasoned cake artists with a ton of experience. To get quotes from acclaimed, award winning cake artists, consider raising your budget to $${high}+.`
          );
        }
      }
    }, 1000),
    []
  );

  useEffect(() => {
    setGuide("");
    setBudget("");

    if (servings <= 10) {
      setLow(75);
    } else if (servings <= 15) {
      setLow(100);
    } else if (servings <= 20) {
      setLow(125);
    } else if (servings <= 30) {
      setLow(160);
    } else if (servings <= 40) {
      setLow(200);
    } else {
      setLow(servings * 5);
    }
  }, [servings]);

  useEffect(() => {
    setMid(low * 2);
    setHigh(low * 3);
  }, [low]);

  useEffect(() => {
    setGuide("");
    evalBudgetGuide(budget, low, mid, high);
  }, [budget, low, mid, high]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ location, date, servings, budget });
  };

  return (
    <Paper>
      <Box px={2} py={4} align="center">
        <Box>
          <Grid container>
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={6} xs={12}>
              <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                  Tell us about your event
                </Typography>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  We work with hundreds of cake artists with diverse skills, experience & prices. Let's find the ones best suited to meet your
                  requirements.
                </Typography>
              </Box>
              <form onSubmit={onSubmit}>
                <Grid container spacing={3} align="left">
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <PlacesAutocomplete
                        onChange={onLocationChange}
                        value={location}
                        required
                        autoFocus
                        helperText="Where is your event?"
                      />
                    </FormControl>
                    {bakers && bakers.length === 0 && (
                      <Box mt={1}>
                        <Alert severity="error">
                          <AlertTitle>Outside coverage area</AlertTitle>We're sorry! We don't have any cake artists in your selected location.
                        </Alert>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <BDatePicker
                        value={date || null}
                        label="Event Date"
                        onChange={(d) => setDate(d)}
                        disablePast
                        required
                        autoOk
                        helperText="When is your event?"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={servings}
                        variant="outlined"
                        type="number"
                        id="servings"
                        label="Servings"
                        name="servings"
                        fullWidth
                        required
                        onChange={(e) => setServings(e.target.value)}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        helperText="How many guests do you plan to serve? Minimum 6 servings."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={budget}
                        variant="outlined"
                        fullWidth
                        required
                        type="number"
                        id="budget"
                        label="Your Budget"
                        name="budget"
                        onChange={(e) => setBudget(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        helperText="We use this to match you with the right cake artists. We don't share your budget with them."
                      />
                    </FormControl>
                    {guide && (
                      <Box mt={1}>
                        <Paper elevation={0} style={{ backgroundColor: "#ececec" }}>
                          <Box p={2}>
                            <Typography variant="body2">{guide}</Typography>
                          </Box>
                        </Paper>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
