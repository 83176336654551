import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import Moment from "react-moment";
import ExpandableImage from "../ExpandableImage";

export default function ChatBubble(props) {
  let style = {
    padding: "7px 10px 5px 10px",
    marginBottom: "1rem",
    maxWidth: "80%",
    display: "inline-block"
  };

  let justify = "flex-start";

  if (props.chat.uid) {
    style.backgroundColor = "#ececec";
  } else {
    style.backgroundColor = "#ceb4c1";
    justify = "flex-end";
  }

  return (
    <Box style={{ display: "flex", justifyContent: justify }}>
      <Paper elevation={0} style={style}>
        {props.chat.pics &&
          props.chat.pics.map((pic, idx) => {
            return (
              <Box key={idx}>
                <ExpandableImage key={idx} src={pic} style={{ height: "125px", borderRadius: "5px" }} />
              </Box>
            );
          })}
        <Typography variant="body2" display="inline">
          {props.chat.msg}
        </Typography>
        <Typography variant="caption" color="textSecondary" style={{ float: "right", marginLeft: "2rem" }}>
          <Moment format="h:mm A">{props.chat.ts}</Moment>
        </Typography>
      </Paper>
    </Box>
  );
}
