import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import StaticPage from "../../common/StaticPage";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import PhoneField from "../../common/input/PhoneField";

export default function BookDemo() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bizName, setBizName] = useState("");
  const [bizType, setBizType] = useState("");
  const [location, setLocation] = useState("");
  const [website, setWebsite] = useState("");
  const [referral, setReferral] = useState("");
  const [consent, setConsent] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    var payload = {
      baker: {
        bizName
      },
      private: {
        name,
        email,
        phone,
        notes: `Location: ${location}. Referred by: ${referral}`
      },
      public: {
        bizType,
        website
      }
    };

    bkstApi({
      method: "POST",
      url: "/bakers/join",
      data: payload
    }).then((res) => {
      setDone(true);
    });
  };

  if (done) {
    return (
      <StaticPage
        icon={CheckCircleOutlinedIcon}
        title="Thank you!"
        desc="We have received your information and will contact you shortly to schedule your demo"
      />
    );
  } else {
    return (
      <Container maxWidth="sm">
        <Box mt={6} mb={4} align="center">
          <Typography variant="h2" gutterBottom>
            Get a free BakeStreet demo
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Fill out the form to schedule a demo and no-pressure quote
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Paper elevation={0}>
            <Box px={4} pt={4} pb={2}>
              {!done && (
                <Grid container spacing={3}>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      value={name}
                      name="name"
                      variant="outlined"
                      fullWidth
                      id="name"
                      label="First & Last Name"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={email}
                      name="email"
                      variant="outlined"
                      fullWidth
                      id="email"
                      label="Email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <PhoneField name="Mobile" value={phone} onChange={(phone) => setPhone(phone)} required />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={bizName}
                      name="bizName"
                      variant="outlined"
                      fullWidth
                      id="bizName"
                      label="Bakery Name"
                      required
                      onChange={(e) => setBizName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={location}
                      name="location"
                      variant="outlined"
                      fullWidth
                      id="location"
                      label="City / Zip"
                      required
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="consent"
                      control={
                        <Checkbox required checked={consent} onChange={(e) => setConsent(e.target.checked)} name="consent" color="secondary" />
                      }
                      label="I consent to receiving calls and SMS messages from BakeStreet to the number I provided"
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Paper>
          <Box my={2}>
            <Button variant="contained" color="primary" size="large" type="submit" fullWidth>
              Submit
            </Button>
          </Box>
        </form>
      </Container>
    );
  }
}
