import { useState } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";
import { Alert } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PhoneField from "../../../common/input/PhoneField";

export default function ContactDetails(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [consent, setConsent] = useState(false);

  const [emailError, setEmailError] = useState("");

  const validateEmail = () => {
    if (email.length > 5 && email.includes("@") && email.includes(".")) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!emailError) {
      props.onSubmit({ name: `${firstName} ${lastName}`, firstName, lastName, email, phone });
    }
  };

  return (
    <Paper>
      <Box px={2} py={4} align="center">
        <Box>
          <Grid container spacing={3}>
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={6} xs={12}>
              <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                  Final Step!
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Get a text notification when cake artists send their quotes. You can expect 3-5 quotes within a few hours.
                </Typography>
              </Box>
              <Box mb={3} align="left">
                <Alert severity="warning" icon={<LockOutlinedIcon />}>
                  We don't share your contact info with cake artists
                </Alert>
              </Box>
              <form onSubmit={onSubmit}>
                <Grid container spacing={3} align="left">
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={firstName}
                        variant="outlined"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        required
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={lastName}
                        variant="outlined" 
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        required
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={email}
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        inputProps={{ "data-hj-allow": "" }}
                        onBlur={validateEmail}
                        error={Boolean(emailError)}
                        helperText={emailError || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <PhoneField name="Mobile" value={phone} onChange={(phone) => setPhone(phone)} required />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="consent"
                      control={
                        <Checkbox required checked={consent} onChange={(e) => setConsent(e.target.checked)} name="consent" color="secondary" />
                      }
                      label="I consent to receiving calls and SMS messages from BakeStreet"
                    />
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button color="primary" variant="contained" type="submit" fullWidth>
                      Get Quotes
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
