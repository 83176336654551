import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChatCanvas from "../../common/chat/ChatCanvas";
import NewMessage from "../../common/chat/NewMessage";
import bkstApi from "../../api";
import PhoneIcon from "@mui/icons-material/Phone";
import { BAKER_PIC_FOLDER } from "../../common/constants";

export default function OrderChat(props) {
  const { order } = props;
  const ref = useRef(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(window.location.hash.includes("chat"));
  const [chat, setChat] = useState(order.customer.chat);

  useEffect(() => {
    if (open) {
      bkstApi(`/order/${order.id}/chat`).then((res) => {
        setChat(res.data.chat || []);
      });
    }
  }, [open]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [chat]);

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  return (
    <Box display="inline">
      <Button fullWidth={props.fullWidth || false} variant={"outlined"} color={"primary"} onClick={(e) => setOpen(true)}>
        Contact
      </Button>
      <Dialog fullScreen={fullScreen} fullWidth maxWidth={fullScreen ? false : "sm"} open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ margin: "0", paddingLeft: "10px", paddingRight: "5px" }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
              <Avatar src={`${BAKER_PIC_FOLDER}/${order.baker.id}/${order.baker.photo}`} sx={{ width: "32px", height: "32px" }}/>
              {order.baker.name}
            </Box>
            <Box>
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={`tel:${order.baker.phone}`}
              >
                <PhoneIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex", flexDirection: "column" }}>
          <Box mb={1} sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <ChatCanvas chat={chat} />
            <Box textAlign="center" ref={ref}>
              <Chip label="VIEW ORDER" color="primary" onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: 0, border: 0, paddingBottom: "10px", paddingRight: "10px", paddingLeft: 0, paddingTop: 0 }}>
          <Box sx={{ flexGrow: "1" }}>
            <NewMessage handleNewMsg={handleNewMsg} uploadFolder={`img/o/${order.id}`} postUrl={`/order/${order.id}/chat`} />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
