import React from "react";
import { Box, Typography } from "@mui/material";
import QuoteDeliveryFee from "./QuoteDeliveryFee";
import moment from "moment";
import InfoPopover from "../../common/info/InfoPopover";

export default function QuoteFooterInfo(props) {
  let { status, expiration } = props;

  const hoursToExpiration = moment(expiration).diff(moment(), "hours");

  if (status === "expired" || hoursToExpiration < 0) {
    return (
      <Box>
        <Box style={{ textDecoration: "line-through" }}>
          <Typography variant="h4" color="textSecondary">
            ${Math.ceil(+props.quote.quote)}
          </Typography>
          <QuoteDeliveryFee option={props.quote.option} fee={props.quote.delivery} />
        </Box>
        <Typography variant="caption" color="error">
          Quote expired&nbsp;
          <InfoPopover text="We might still be able to accept your order. Contact us for an updated quote." />
        </Typography>
      </Box>
    );
  } else if (props.quote.quote) {
    return (
      <Box>
        <Typography variant="h4">${Math.ceil(+props.quote.quote)}</Typography>
        <QuoteDeliveryFee option={props.quote.option} fee={props.quote.delivery} />
        <Box>
          <Typography variant="caption" color={hoursToExpiration > 6 ? "textSecondary" : "error"}>
            quote expires {moment(expiration).fromNow()}&nbsp;
            <InfoPopover text="We have limited availability & orders are accepted on a first-come, first-serve basis." />
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return <Box />;
  }
}
