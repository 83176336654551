import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import currency from "currency.js";
import { Box, Container, Divider, FormControlLabel, Grid, LinearProgress, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router";
import IconText from "../../common/IconText";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Images from "../../request/components/Images";
import OrderPaymentSummary from "../component/OrderPaymentSummary";
import TextWithReadMore from "../../common/TextWithReadMore";
import moment from "moment";
import { Alert } from "@mui/material";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import StripePaymentElement from "../component/StripePaymentElement";
import OrderCancelationPolicy from "../component/OrderCancelationPolicy";
import LatePaymentPolicy from "../component/LatePaymentPolicy";
import OrderRefundPolicy from "../component/OrderRefundPolicy";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function CheckoutV2(props) {
  const navigate = useNavigate();

  const { reqId, quoteId } = useParams();
  const [request, setRequest] = useState();
  const [quote, setQuote] = useState();
  const [baker, setBaker] = useState();

  const [tipPct, setTipPct] = useState();
  const [tip, setTip] = useState(0);

  const [shipping, setShipping] = useState("pickup");
  const [delivery, setDelivery] = useState(0);

  const [fee, setFee] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [due, setDue] = useState(0);

  const [clientSecret, setClientSecret] = useState("");
  const [sid, setSid] = useState();

  const [street, setStreet] = useState("");

  const [loading, setLoading] = useState(false);

  const stripeOptions = {
    clientSecret,
    appearance: {
      labels: "floating",
      variables: {
        colorPrimary: "#78374a",
        colorDanger: "#df1b41",
        borderRadius: "4px"
      }
    }
  };

  useEffect(() => {
    bkstApi(`/requests/${reqId}/quotes/${quoteId}/checkout-v2`).then((res) => {
      setClientSecret(res.data.clientSecret);
      setSid(res.data.sid);
      setRequest(res.data.request);
      setQuote(res.data.quote);
      setBaker(res.data.baker);
      setSubtotal(res.data.subtotal);
      setFee(res.data.fee);
      setTotal(res.data.total);
      setDue(res.data.due);

      if (res.data.quote.option === "free") {
        setShipping("delivery");
      }

      addEvent("checkout");
    });
  }, []);

  useEffect(() => {
    if (quote) {
      updatePaymentIntent();
    }
  }, [tipPct, shipping]);

  const updatePaymentIntent = () => {
    setLoading(true);
    const payload = { sid, items: { tipPct, shipping, street } };

    bkstApi({
      method: "POST",
      url: `/requests/${reqId}/quotes/${quoteId}/update-checkout-v2`,
      data: payload
    }).then((res) => {
      setSubtotal(res.data.subtotal);
      setDelivery(res.data.delivery);
      setFee(res.data.fee);
      setTip(res.data.tip);
      setTotal(res.data.total);
      setDue(res.data.due);
      setLoading(false);
    });
  };

  const addEvent = (eventType) => {
    bkstApi.post(`/request/${reqId}/quote/${quoteId}/event`, {
      eventType
    });
  };

  const handleTipChange = (event, value) => {
    setTipPct(value);
  };

  const validate = () => {
    return true;
  };

  // NOW RENDER
  if (request && quote && baker) {
    let snippets = `${moment(request.date).format("MMM DD")}`;

    const stripeFooter = (
      <Box mt={4} align="center">
        <img
          width="125"
          src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/img%2Fh%2Fstripe.svg?alt=media&token=86fe6d00-eae6-45e2-924b-d9e0e5da25a9"
        />
        <Typography display="block" variant="caption" color="textSecondary">
          Your payment will be processed by Stripe, a market leader in online payments. BakeStreet can't see your payment details.{" "}
          <a target="_blank" href="https://stripe.com/">
            Learn More
          </a>
        </Typography>
      </Box>
    );

    const daysToEvent = moment(request.date).diff(moment(), "days");
    let showDepositOption = request.cid && daysToEvent > 7;

    return (
      <Container maxWidth="sm">
        <Box pt={5} pb={3}>
          <Box>
            <Typography variant="overline" color="textSecondary">
              Confirm & book
            </Typography>
          </Box>
          <Paper>
            <Box px={2} py={4} mb={4}>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Images pics={request.pics || [request.pic1, request.pic2, request.pic3].filter((i) => i)} hideArrows={true} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>
                        {request.title}
                        <small style={{ color: "#777" }}>{` by ${baker.name}`}</small>
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="caption"
                        dangerouslySetInnerHTML={{
                          __html: snippets
                        }}
                      ></Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} align="right">
                  <Typography variant="subtitle1">{currency(quote.quote).format()}</Typography>
                </Grid>
              </Grid>
              {quote.note && quote.note.length > 2 && (
                <Grid item xs={12}>
                  <Paper elevation={0} style={{ backgroundColor: "#F7F7F7" }}>
                    <Box p={1} mt={1}>
                      <TextWithReadMore data={quote.note} limit={100} />
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Box>
          </Paper>
          <Box style={{ color: "#888" }}>
            <IconText icon={LocalShippingOutlinedIcon} text="Shipping" />
          </Box>
          <Paper>
            <Box px={2} py={3} mb={4}>
              <Grid container spacing={1}>
                <Grid item xs={9} justifyContent={"flex-start"}>
                  <RadioGroup name="shipping" value={shipping} onChange={(e) => setShipping(e.target.value)}>
                    {quote.option !== "free" && (
                      <Box mb={1}>
                        <FormControlLabel value="pickup" control={<Radio />} label={`Pickup in ${baker.location.city}, ${baker.location.state}`} />
                        <Typography variant="body2" color="textSecondary">
                          Exact address shared after order confirmation
                        </Typography>
                      </Box>
                    )}
                    {(quote.option === "free" || quote.option === "fee") && (
                      <Box>
                        <FormControlLabel
                          value="delivery"
                          control={<Radio />}
                          label={quote.option === "free" ? "Free delivery" : `Curbside delivery for $${quote.delivery}`}
                        />
                        <Typography variant="body2" color="textSecondary">
                          {quote.option === "free"
                            ? `Free delivery to ${request.location}`
                            : `Curbside delivery to ${request.location} for $${quote.delivery}`}
                        </Typography>
                      </Box>
                    )}
                  </RadioGroup>
                </Grid>
                <Grid item xs={3} align="right">
                  <Typography variant="subtitle1">{currency(delivery).format()}</Typography>
                </Grid>
              </Grid>
              {shipping === "delivery" && (
                <Box p={2} mt={3} style={{ backgroundColor: "#f7f7f7" }}>
                  <Box pb={2}>
                    <Typography variant="caption">Enter street address in {request.location.toUpperCase()}</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="street"
                        value={street}
                        variant="outlined"
                        required
                        fullWidth
                        id="street"
                        label="Street address"
                        onChange={(e) => setStreet(e.target.value)}
                        onBlur={updatePaymentIntent}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Paper>
          <Box style={{ color: "#888" }}>
            <IconText icon={LockOutlinedIcon} text="Secure Payment" />
          </Box>
          <Paper>
            {loading && <LinearProgress />}
            <Box px={2} py={2}>
              <Box align="left">
                <Alert icon={<SecurityOutlinedIcon />} severity="success">
                  BakeStreet protects you against fraud & disputes by paying your cake artist <u>after</u> your order is complete.
                </Alert>
              </Box>
              <Box>
                <OrderPaymentSummary
                  subtotal={subtotal}
                  delivery={delivery}
                  tip={tip}
                  fee={fee}
                  total={total}
                  due={due}
                  date={moment(request.date).subtract(7, "days").format("MMM DD")}
                />
              </Box>
              <Box mb={3}>
                <Divider />
              </Box>
              <Box>
                <Typography variant="overline" color="textSecondary" gutterBottom>
                  Pay with
                </Typography>
              </Box>
              <Box px={2} py={4} style={{ backgroundColor: "#F7F7f7" }}>
                <Elements stripe={promise} options={stripeOptions}>
                  <StripePaymentElement
                    amount={currency(due).format()}
                    disabled={loading}
                    validate={validate}
                    returnUrl={`${window.location.origin}/payment-status?req_id=${reqId}`}
                    showPolicy={true}
                  />
                </Elements>
              </Box>
              {stripeFooter}
            </Box>
          </Paper>
          <Box mt={10}>
            <OrderCancelationPolicy date={request.date} total={total} tip={tip} delivery={delivery} ts={Date.now()} />
            <LatePaymentPolicy />
            <OrderRefundPolicy />
          </Box>
        </Box>
      </Container>
    );
  } else {
    return <LinearProgress />;
  }
}
