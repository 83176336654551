import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Rating from '@mui/material/Rating';
import BakerApproxLocation from "../common/gmaps/BakerApproxLocation";
import { STORAGE_BUCKET } from "../common/constants";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import IconText from "../common/IconText";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Alert, AlertTitle } from '@mui/material';

export default function NearbyBakers(props) {
  if (props.bakers && props.bakers.length > 0) {
    return (
      <Box>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {props.title}
        </Typography>
        <Paper elevation={0} style={{ backgroundColor: "#F7F7F7" }}>
          <Box p={1}>
            <Carousel infiniteLoop={true} autoPlay={true} interval={2000} stopOnHover={false} transitionTime={1000} showThumbs={false} showIndicators={false} showStatus={false}>
              {props.bakers.map((baker) => {
                return (
                  <Box key={baker.id}>
                    <Grid container spacing={2} style={{ justifyContent: "center" }}>
                      <Grid item>
                        <img
                          src={`${STORAGE_BUCKET}/images/bakers/${baker.id}/portfolio/0.jpeg`}
                          style={{ width: "90px", height: "90px", objectFit: "cover", borderRadius: "4px" }}
                        />
                      </Grid>
                      <Grid item align="left">
                        <Typography>{baker.name}</Typography>
                        <Rating precision={0.25} name="rating" value={Number(baker.rating)} readOnly size="small" />
                        <Box style={{ color: "#666" }}>
                          <IconText variant="body2" icon={CakeOutlinedIcon} text={`${baker.orders} orders`} />
                          <BakerApproxLocation location={baker.location} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>Sorry!</AlertTitle>
          We don't have any cake artists that serve your location
        </Alert>
      </Box>
    );
  }
}
