import React from "react";
import { Box, Container, Fab, Grid, Typography } from "@mui/material";
import Testimonial from "../../common/Testimonial";
import { BAKER_PIC_FOLDER, STORAGE_BUCKET } from "../../common/constants";
import { Link as RouterLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const TESTIMONIAL = [
  {
    id: "-MROCJAn2bWELBXJnKQ-",
    bizName: "Sweet Touch by Iva",
    name: "Iva",
    link: "https://sweettouchbyiva.com",
    text: "BakeStreet provided the tools to take my custom cake and everyday desserts business to the next level. Sales are up, and I can manage my business from anywhere, anytime!"
  },
  {
    id: "-MY86o9vhSRH5kmdtkFq",
    bizName: "Chef JhoanEs",
    name: "Jhoan",
    link: "https://chefjhoanes.com",
    text: "Bake Street streamlined our operations, providing organization and efficiency. The user-friendly platform ensures quick, secure orders and effective weekly planning. We are delighted with Bake Street."
  },
  {
    id: "m7w2nldh2VlpJijvH2tC",
    bizName: "Mia's Brooklyn Bakery",
    name: "Agapios",
    link: "https://app.miasbrooklyn.com",
    text: "Bakestreet has revolutionized our custom order process at Mia's, leading to amazing success with custom cakes business. We're surprised at how we used to operate before using Bakestreet."
  }
];

export default function SaaSHomePage(props) {
  return (
    <Box>
      <div id="saas-hero">
        <Container maxWidth="sm">
          <Box sx={{ color: "#ECECEC", marginTop: "-64px" }}>
            <Typography component="h1" sx={{ fontSize: "2.5rem", fontWeight: "700", textAlign: "center", lineHeight: 1.4 }}>
              AI-powered <u>backbone</u> for your bakery business
            </Typography>
            <Box my={2}>
              <Typography sx={{ fontSize: "1.25em", color: "#ECECEC", textAlign: "center" }}>
                Grow sales, streamline operations & elevate customer experience
              </Typography>
            </Box>
          </Box>
        </Container>
      </div>
      <Box px={2} py={10} style={{ backgroundColor: "#FFFFFF" }} align="center">
        <Typography component="h2" sx={{ fontSize: "2.2rem", fontWeight: "700", textAlign: "center", lineHeight: 1.5 }}>
          Trusted by the top <br />
          <u>
            tech-<i>forward</i>
          </u>
          &nbsp;&nbsp; bakeries
        </Typography>
        <Box mt={6}>
          <Grid container>
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={6} xs={12}>
              <Testimonial data={TESTIMONIAL[0]} />
              <Box mt={6} sx={{ display: "flex", gap: "20px", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                  <Box>
                    <img src={`${BAKER_PIC_FOLDER}/6WOVOXtelMJ1ZwfEWs17/logo400.webp`} style={{ width: "75px", borderRadius: "4px", filter: "grayscale(1)" }} />
                  </Box>
                  <Box>
                    <img src={`${BAKER_PIC_FOLDER}/6VRiUORmuZ7IORV5CONz/logo400.webp`} style={{ width: "75px", borderRadius: "4px", filter: "grayscale(1)" }} />
                  </Box>
                  <Box>
                    <img src={`${BAKER_PIC_FOLDER}/-MROBNjX5PgS42T785uI/logo400.webp`} style={{ width: "75px", borderRadius: "4px", filter: "grayscale(1)" }} />
                  </Box>
                 
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box px={2} py={15} style={{ backgroundColor: "#F7F7F7" }}>
        <Container maxWidth="md">
          <Box textAlign={"center"} mb={10}>
            <Typography variant="h2" gutterBottom>
              Grow Sales
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Grow your bakery sales up to 27% in 6-12 months with BakeStreet's tailored solutions
            </Typography>
          </Box>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item sm={6} xs={12}>
              <Box>
                <img src={`${STORAGE_BUCKET}/img/h/review.png`} style={{ width: "100%" }} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h4" gutterBottom>
                More Customers
              </Typography>
              <Typography variant="subtitle1">
                Sit back and let our AI-powered software grow your customer base up to 5x with positive google reviews and enhanced SEO. Rank higher
                in search results.
              </Typography>
            </Grid>
          </Grid>
          <Box py={5}></Box>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item sm={6} xs={12} order={{ sm: 1, xs: 2 }}>
              <Typography variant="h4" gutterBottom>
                More Orders
              </Typography>
              <Typography variant="subtitle1">
                Turn your website traffic into paying customers with easy-to-use quotes and streamlined online ordering. Designed for the complex
                bakery needs.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12} order={{ sm: 2, xs: 1 }}>
              <Box>
                <img src={`${STORAGE_BUCKET}/img/h/ecommerce.png`} style={{ width: "100%" }} />
              </Box>
            </Grid>
          </Grid>
          <Box py={5}></Box>

          <Grid container spacing={4} alignItems={"center"}>
            <Grid item sm={6} xs={12}>
              <Box>
                <img src={`${STORAGE_BUCKET}/img/h/marketing.png`} style={{ width: "100%" }} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h4" gutterBottom>
                More Repeat Customers
              </Typography>
              <Typography variant="subtitle1">
                Convert your happy customers into regulars with AI-powered marketing campaigns. Messages go out when customers are most likely to
                order.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box px={2} py={15} style={{ backgroundColor: "#ECECEC" }}>
        <Container maxWidth="md">
          <Box textAlign={"center"} mb={10}>
            <Typography variant="h2" gutterBottom>
              Save Time
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Cut 70% of manual tasks and boost efficiency with BakeStreet's innovative solutions
            </Typography>
          </Box>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item sm={6} xs={12} order={{ sm: 1, xs: 2 }}>
              <Typography variant="h4" gutterBottom>
                Minimize back & forth
              </Typography>
              <Typography variant="subtitle1">
                Eliminate endless conversations and follow-ups on custom cake orders. Our AI-powered software saves you up to 70% of your time by
                handling quotes, follow-ups, orders and payments. Focus on what matters most: your work.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12} order={{ sm: 2, xs: 1 }}>
              <Box>
                <img src={`${STORAGE_BUCKET}/img/h/chat.png`} style={{ width: "100%" }} />
              </Box>
            </Grid>
          </Grid>
          <Box py={5}></Box>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item sm={6} xs={12}>
              <Box>
                <img src={`${STORAGE_BUCKET}/img/h/streamline.png`} style={{ width: "100%" }} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h4" gutterBottom>
                Streamline operations
              </Typography>
              <Typography variant="subtitle1">
                No missed orders, bad reviews, or refunds. Manage quotes, track orders and get baking plan—all in one place. Perfect for owners,
                managers, and staff.
              </Typography>
            </Grid>
          </Grid>
          <Box py={5}></Box>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item sm={6} xs={12} order={{ sm: 1, xs: 2 }}>
              <Typography variant="h4" gutterBottom>
                Manage On the Go
              </Typography>
              <Typography variant="subtitle1">
                Say goodbye to messy binders. Stay on top of sales, payouts, and business planning - 24/7 from anywhere. Run your business like a pro.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12} order={{ sm: 2, xs: 1 }}>
              <Box>
                <img src={`${STORAGE_BUCKET}/img/h/manage.png`} style={{ width: "100%" }} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <div style={{ backgroundColor: "#fff", paddingTop: "6rem", paddingBottom: "6rem" }}>
        <Container maxWidth="lg">
          <Box align="center" pb={2}>
            <Box mb={8}>
              <Typography variant="h2" gutterBottom>
                Grow Sales
              </Typography>
              <Typography variant="subtitle1"></Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box mb={2}>
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fprofile.svg?alt=media&token=32487ece-7693-42c0-80a1-fbaca59b90fe"
                    style={{ height: "125px" }}
                  ></img>
                </Box>
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  More Customers
                </Typography>
                <Typography variant="body1">
                  Sit back and let our AI-powered software grow your customer base up to 5x with positive google reviews and enhanced SEO. Rank higher
                  in search results.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box mb={2}>
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fops.svg?alt=media&token=548ed00e-2568-48a1-a6ac-b353dd85b4ad"
                    style={{ height: "125px" }}
                  ></img>
                </Box>
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  More Orders
                </Typography>
                <Typography variant="body1">
                  Turn your website traffic into paying customers with easy-to-use quotes and streamlined online ordering. Designed for the complex
                  bakery needs.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box mb={2}>
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fpay.svg?alt=media&token=f623664d-001c-4bcf-83ee-8d9f0d05955a"
                    style={{ height: "125px" }}
                  ></img>
                </Box>
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  More Repeat Business
                </Typography>
                <Typography variant="body1">
                  Convert your happy customers into regulars with AI-powered marketing campaigns. Messages go out when customers are most likely to
                  order.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {cta}
        </Container>
      </div> */}
      {/*
      <Box style={{ backgroundColor: "#fafafa" }}>
        <Container id="profile" maxWidth="md">
          <Box py={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fprofile_shot.png?alt=media&token=899d8d94-2375-4e0f-871c-98e01c7c742e"
                  style={{ width: "100%" }}
                ></img>
              </Grid>
              <Grid item container direction="column" display="flex" justifyContent="center" xs={12} sm={8}>
                <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                  Free business website
                </Typography>
                {profileFeatures.map((t, i) => {
                  return (
                    <Typography variant="subtitle1" key={i}>
                      <CheckIcon style={{ position: "relative", top: "5px" }} />
                      &nbsp;&nbsp;{t}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#fff" }}>
        <Container id="tools" maxWidth="md">
          <Box py={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} id="flip-one">
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fget-quote-2.png?alt=media&token=3d08051f-563a-43a6-b80e-c4ab31af77e9"
                  style={{ width: "100%" }}
                ></img>
              </Grid>
              <Grid item container direction="column" display="flex" justifyContent="center" xs={12} sm={8} id="flip-two">
                <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                  Productivity tools
                </Typography>
                {productivityFeatures.map((t, i) => {
                  return (
                    <Typography variant="subtitle1" key={i}>
                      <CheckIcon style={{ position: "relative", top: "5px" }} />
                      &nbsp;&nbsp;{t}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#fafafa" }}>
        <Container id="payment" maxWidth="md">
          <Box py={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fcheckout.png?alt=media&token=e4a9212a-e830-4743-83bd-21fb5b637c78"
                  style={{ width: "100%" }}
                ></img>
              </Grid>
              <Grid item container direction="column" display="flex" justifyContent="center" xs={12} sm={8}>
                <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                  Easy Payments
                </Typography>
                {paymentFeatures.map((t, i) => {
                  return (
                    <Typography variant="subtitle1" key={i}>
                      <CheckIcon style={{ position: "relative", top: "5px" }} />
                      &nbsp;&nbsp;{t}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
              </Box> */}
      <Box sx={{ position: "fixed", width: "100%", bottom: "20px", display: "flex", justifyContent: "center" }}>
        <Fab variant="extended" color="primary" component={RouterLink} to={`/book-demo`} sx={{ fontSize: "24px", fontWeight: "700", height: "50px" }}>
          Book a Demo
        </Fab>
      </Box>
    </Box>
  );
}
