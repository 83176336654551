import React from "react";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import currency from "currency.js";

export default function Invoices(props) {
  const getBgColor = (status) => {
    if (status === "open") {
      return "#e67e22";
    } else if (status === "paid" || status === "refund") {
      return "#1abc9c";
    } else {
      return "#AAA";
    }
  };

  return (
    <Box my={6}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography variant="overline" color="textSecondary">
            ADD-ONS
          </Typography>
        </Grid>
      </Grid>
      <Paper elevation={0}>
        <Box px={2} py={2}>
          {Object.entries(props.data)
            .sort()
            .map(([key, value]) => {
              return (
                <Box py={1}>
                  <Grid key={key} container alignItems="center">
                    <Grid item xs={8} sm={10}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={9}>
                          <Typography variant="body2" color="textSecondary">
                            {value.description}
                          </Typography>
                          {value.status === "open" && (
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              dangerouslySetInnerHTML={{
                                __html: `&#128338; sent ${moment(value.ts).fromNow()}`
                              }}
                            ></Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Chip size="small" label={value.status} style={{ color: "#fff", backgroundColor: getBgColor(value.status) }} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} sm={2} align="right">
                      <Typography variant="overline">{currency(value.amount.addOn).format()}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </Paper>
    </Box>
  );
}
