import React from "react";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import currency from "currency.js";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

export default function OrderPayment(props) {
  return (
    <Paper elevation={0}>
      <Box px={2} py={2}>
        <Grid container spacing={3} mb={1}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography variant="body2" color="textSecondary">
              {currency(props.order.subtotal).format()}
            </Typography>
          </Grid>
        </Grid>
        {props.order.delivery > 0 && (
          <Grid container spacing={3} mb={1}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Delivery
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography variant="body2" color="textSecondary">
                {currency(props.order.delivery).format()}
              </Typography>
            </Grid>
          </Grid>
        )}
        {props.order.tip > 0 && (
          <Grid container spacing={3} mb={1}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Tip
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography variant="body2" color="textSecondary">
                {currency(props.order.tip).format()}
              </Typography>
            </Grid>
          </Grid>
        )}
        {props.order.discount > 0 && (
          <Grid container spacing={3} mb={1}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Discount
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography>{currency(props.order.discount).format()}</Typography>
            </Grid>
          </Grid>
        )}
        {props.order.fee > 0 && (
          <Grid container spacing={3} mb={1}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Service Fee
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography variant="body2" color="textSecondary">
                {currency(props.order.fee).format()}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3} mb={1}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              Total
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography>{currency(props.order.total).format()}</Typography>
          </Grid>
        </Grid>
        {props.order.due > 0 && (
          <Box>
            <Grid container spacing={3} mb={1}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Amount Paid
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography>{currency(props.order.paid).format()}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={1}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Balance Due {props.date && moment(props.date).subtract(7, "days").format("MMM DD")}
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography display="inline">
                  {currency(props.order.due).format()}{" "}
                  <Link underline="always" component={RouterLink} to={`/order/${props.orderId}/balance-payment`}>
                    Pay Now
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
