import React from "react";
import currency from "currency.js";
import { Box, Grid, Paper, Typography } from "@mui/material";

export default function OrderPaymentSummary(props) {
  return (
    <Paper elevation={0}>
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="caption" color="textSecondary">
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <Typography variant="caption" color="textSecondary">
              {currency(props.subtotal).format()}
            </Typography>
          </Grid>
        </Grid>
        {props.delivery > 0 && (
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="caption" color="textSecondary">
                Delivery fee
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="caption" color="textSecondary">
                {currency(props.delivery).format()}
              </Typography>
            </Grid>
          </Grid>
        )}
        {props.fee > 0 && (
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="caption" color="textSecondary">
                Service fee
                {/* &nbsp; <TotalAmountPopover quote={props.subtotal} fee={props.fee} delivery={props.delivery} tip={props.tip} /> */}
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="caption" color="textSecondary">
                {currency(props.fee).format()}
              </Typography>
            </Grid>
          </Grid>
        )}
        {+props.tip > 0 && (
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="caption" color="textSecondary">
                Tip
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="caption" color="textSecondary">
                {currency(props.tip).format()}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="overline" color="textSecondary">
                TOTAL (USD)
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="overline" color="textSecondary">
                {currency(props.total).format()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {props.due < props.total && (
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography variant="caption" color="textSecondary">
                  Payment due on {props.date}
                </Typography>
              </Grid>
              <Grid item xs={3} align="right">
                <Typography variant="caption" color="textSecondary">
                  {currency(props.total - props.due).format()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography variant="body1">Payment due today</Typography>
              </Grid>
              <Grid item xs={3} align="right">
                <Typography variant="body1" style={{ fontWeight: "700" }}>
                  {currency(props.due).format()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
