import React from "react";
import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import { BAKER_PIC_FOLDER } from "../common/constants";

export default function Testimonial(props) {
  return (
    <Paper sx={{ backgroundColor: "#F7F7F7" }}>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <img src={`${BAKER_PIC_FOLDER}/${props.data.id}/photo400.webp`} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12} sm={9} align="left">
            <Typography variant="subtitle1" gutterBottom>
              {props.data.text}
            </Typography>
            <Box mt={2} sx={{ display: "flex", gap: "15px" }}>
              <Avatar src={`${BAKER_PIC_FOLDER}/${props.data.id}/logo400.webp`} style={{ width: "50px", height: "50px" }} />
              <Box>
                <Typography variant="h6">{props.data.name}, Owner</Typography>
                <Typography variant="body2">
                  {props.data.bizName}, New York
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
