import React, { useEffect, useState, useRef } from "react";
import { Backdrop, Box, CircularProgress, Container, Grow, LinearProgress } from "@mui/material";
import bkstApi from "../../api";
import Verify from "../../wizard/Verify";
import Design from "../components/wizard/Design";
import CakeDetails from "../components/wizard/CakeDetails";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import ContactDetails from "../components/wizard/ContactDetails";
import Event from "../components/wizard/Event";
import Location from "../components/wizard/Location";

export default function MktGetQuote(props) {
  let location = useLocation();

  const navigate = useNavigate();

  const [step, setStep] = useState("");
  const [progress, setProgress] = useState(0);

  const [req, setReq] = useState({});
  const [reqId, setReqId] = useState();

  const stepRef = useRef(null);

  useEffect(() => {
    if (location.pathname === `/get-quote` || location.pathname === `/get-quote/design`) {
      setStep("design");
      setProgress(20);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/get-quote/customize` && (req.pics || location.state)) {
      if (location.state && location.state.req) {
        setData(location.state.req);
      }
      setStep("customize");
      setProgress(40);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/get-quote/event` && req.pics) {
      setStep("event");
      setProgress(75);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/get-quote/contact` && req.pics && req.servings && req.location && req.budget) {
      setStep("contact");
      setProgress(95);
      stepRef.current.scrollIntoView();
    } else {
      navigate("/get-quote");
    }
  }, [location.pathname]);

  const setData = (data) => {
    Object.assign(req, data);
    setReq(req);
  };

  const handleLocation = (data) => {
    setData(data);

    if (req.pics) {
      navigate("/get-quote/event");
    } else {
      navigate("/get-quote/design");
    }
  };

  const handleDesign = (data) => {
    setData(data);
    navigate("/get-quote/customize");
  };

  const handleEvent = (data) => {
    setData(data);
    navigate("/get-quote/contact");
  };

  const handleCustomize = (data) => {
    setData(data);
    //if (req.location) {
    navigate("/get-quote/event");
    //} else {
    //  navigate("/get-quote/location");
    //}
  };

  const handleBudget = (data) => {
    setData(data);
    navigate("/get-quote/contact");
  };

  const submit = (data) => {
    setStep("submit");
    let payload = req;
    Object.assign(payload, data);

    // check utm params
    const utmParams = sessionStorage.getItem("utm");
    if (utmParams) {
      payload.utm = JSON.parse(utmParams);
    }

    bkstApi.post("/requests", payload).then((res) => {
      setReqId(res.data.id);
      setStep("verify");
      setProgress(99);

      // publish Adword conversion event
      window.dataLayer.push({
        event: "verify"
      });
    });
  };

  return (
    <Container maxWidth="md">
      <Box my={4} ref={stepRef} />
      <Box py={3}>
        <LinearProgress color="primary" variant="determinate" value={progress} />
        {step === "design" && <Design onSubmit={handleDesign} />}
        {step === "location" && (
          <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box>
              <Location onSubmit={handleLocation} />
            </Box>
          </Grow>
        )}
        {step === "customize" && (
          <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box>
              <CakeDetails pics={req.pics} onSubmit={handleCustomize} />
            </Box>
          </Grow>
        )}
        {step === "event" && (
          <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box>
              <Event location={req.location} onSubmit={handleEvent} />
              {/* <Budget servings={req.servings} location={req.location} onSubmit={handleBudget} /> */}
            </Box>
          </Grow>
        )}
        {step === "contact" && (
          <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box>
              <ContactDetails location={req.location} onSubmit={submit} />
            </Box>
          </Grow>
        )}
        {step === "submit" && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {step === "verify" && (
          <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box>
              <Verify reqId={reqId} phone={req.phone} />
            </Box>
          </Grow>
        )}
      </Box>
    </Container>
  );
}
