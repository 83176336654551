import React from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import RequestToBook from "./RequestToBook";
import HowItWorksPopup from "../../request/components/HowItWorksPopup";

export default function QuoteFooterAction(props) {
  let { status, paymentDueFactor, quote } = props;
  const amount = Math.ceil(+props.quote.quote * +paymentDueFactor);

  const howItWorks = <HowItWorksPopup addEvent={props.addEvent} />;

  if (status === "active" && props.quote.quote) {
    return (
      <Box align="center">
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={`/requests/${props.reqId}/quotes/${quote.baker && quote.baker.id}/checkout`}
        >
          Book for ${amount}
        </Button>
        <Box mt={0.5}>
          {paymentDueFactor < 1 && (
            <Typography variant="caption" color="textSecondary" display="block">
              Pay part now, part later
            </Typography>
          )}
        </Box>
        {howItWorks}
      </Box>
    );
  } else if (status === "rbook") {
    return (
      <Box p={0.5}>
        <RequestToBook reqId={props.reqId} quote={props.quote} />
        {howItWorks}
      </Box>
    );
  } else if (status === "expired") {
    return (
      <Box p={0.5}>
        <RequestToBook reqId={props.reqId} quote={props.quote} />
        {howItWorks}
      </Box>
    );
  } else if (status === "paid") {
    return (
      <Box align="center" p={0.5}>
        <Chip size="medium" label="paid" />
      </Box>
    );
  } else {
    return <Box />;
  }
}
