import { useState, useEffect } from "react";
import { Box, Button, FormControl, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import PlacesAutocomplete from "../../../common/PlacesAutocomplete";
import NearbyBakers from "../../../wizard/NearbyBakers";
import bkstApi from "../../../api";

export default function Location(props) {
  const [location, setLocation] = useState("");
  const [bakers, setBakers] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location) {
      setBakers();
      setLoading(true);
      bkstApi(`/bakers?state=${location.state}&lat=${location.coord.lat}&lng=${location.coord.lng}`).then((res) => {
        setBakers(res.data);
        setLoading(false);
      });
    }
  }, [location]);

  const onChange = (value) => {
    setLocation(value);
    document.activeElement.blur()
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (bakers && bakers.length > 0) {
      props.onSubmit({ location });
    }
  };

  return (
    <Paper>
      <Box px={2} pt={5} pb={10} align="center">
        <Box>
          <Grid container>
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={6} xs={12}>
              <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                  Where is your event?
                </Typography>
                <Typography color="textSecondary" variant="subtitle1" gutterBottom>
                  Let's check if we have any cake artists that serve your event location
                </Typography>
              </Box>
              <form onSubmit={onSubmit}>
                <Grid container spacing={3} align="left">
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <PlacesAutocomplete onChange={onChange} value={location} required autoFocus/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {loading && <LinearProgress />}
                    {bakers && (
                      <NearbyBakers
                        bakers={bakers}
                        title={bakers.length > 0 ? `Yay, ${bakers.length >= 10 ? "10+" : bakers.length} cake artists found!` : ""}
                      />
                    )}
                  </Grid>
                  {bakers && bakers.length > 0 && (
                    <Grid item xs={12}>
                      <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
                        Get Quotes
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
