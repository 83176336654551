import React, { useState } from "react";
import { Link, Typography } from "@mui/material";

export default function TextWithReadMore(props) {
  const [showPart, setShowPart] = useState(true);

  const showMore = (e) => {
    e.preventDefault();
    setShowPart(false);
    props.addEvent && props.addEvent("expand");
  };

  const limit = Number(props.limit);

  return (
    <Typography variant={props.variant || "body2"} color={props.color || "textSecondary"} style={{ textAlign: "justify" }}>
      {showPart && props.data && props.data.length > limit ? `${props.data.substring(0, limit - 1).trim()}...  ` : props.data}
      {showPart && props.data && props.data.length > limit && (
        <Link color="primary" underline="always" onClick={showMore} style={{ cursor: "pointer" }}>
          Show More
        </Link>
      )}
    </Typography>
  );
}
