import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import currency from "currency.js";
import { Box, Container, Grid, Link, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../../api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link as RouterLink } from "react-router-dom";
import StripePaymentElement from "../component/StripePaymentElement";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function OrderBalancePay(props) {
  const { orderId } = useParams();
  const [payment, setPayment] = useState();

  const stripeOptions = {
    clientSecret: payment && payment.clientSecret,
    appearance: {
      labels: "floating",
      variables: {
        colorPrimary: "#78374a",
        colorDanger: "#df1b41",
        borderRadius: "4px"
      }
    }
  };

  useEffect(() => {
    bkstApi(`/order/${orderId}/balance-payment`).then((res) => {
      setPayment(res.data);
    });
  }, []);

  const validate = () => {
    return true;
  };

  if (payment) {
    return (
      <Container maxWidth="sm">
        <Box py={5}>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom>
              Balance Payment
            </Typography>
            <Typography color="textSecondary">
              Pay the balance amount for your{" "}
              <Link component={RouterLink} to={`/order/${orderId}`} underline="always">
                {payment.title}
              </Link>{" "}
              order
            </Typography>
          </Box>
          <Paper>
            <Box px={3} pt={2} pb={5}>
              <Box my={2}>
                <Paper elevation={0} style={{ backgroundColor: "#F7F7F7", padding: "1rem" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography variant="body1" color="textSecondary">
                        Balance Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <Typography variant="h6">{currency(payment.amount).format()}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Elements stripe={promise} options={stripeOptions}>
                <StripePaymentElement
                  amount={currency(payment.amount).format()}
                  validate={validate}
                  returnUrl={`${window.location.origin}/payment-status?order_id=${orderId}`}
                />
              </Elements>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  } else {
    return <LinearProgress />;
  }
}
