import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Testimonial from "../../common/Testimonial";
import { BAKER_PIC_FOLDER } from "../../common/constants";
import { Link as RouterLink } from "react-router-dom";

const testimonials = [
  {
    id: "-MROCJAn2bWELBXJnKQ-",
    bizName: "Sweet Touch by Iva",
    name: "Iva",
    link: "https://sweettouchbyiva.com",
    text: "Thanks to BakeStreet's software, growing my bakery business has become effortless."
  },
  {
    id: "-MY86o9vhSRH5kmdtkFq",
    bizName: "Chef JhoanEs",
    name: "Jhoan",
    link: "https://chefjhoanes.com",
    text: "Bake Street streamlined our operations, providing organization and efficiency. The user-friendly platform ensures quick, secure orders and effective weekly planning. We are delighted with Bake Street."
  },
  {
    id: "m7w2nldh2VlpJijvH2tC",
    bizName: "Mia's Brooklyn Bakery",
    name: "Agapios",
    link: "https://app.miasbrooklyn.com",
    text: "Bakestreet has revolutionized our custom order process at Mia's, leading to amazing success with custom cakes business. We're surprised at how we used to operate before using Bakestreet."
  }
];
const profileFeatures = [
  "Elevate your online presence with a professional & modern website",
  "Showcase your talent with a portfolio of high-resolution images",
  "Add image search & filtering capability to your portfolio",
  "Build trust & credibility with online rating & reviews",
  'Simplify online inquiries with our intuitive 3-step "Get Quote" flow'
];

const productivityFeatures = [
  "Manage open requests & orders in your personalized dashboard",
  "Polish your brand by sending professional email & text messages",
  "Reduce back & forth communication with optimized workflows",
  "Improve conversion rate with automated reminders & notifications",
  "Access performance reports to help you fine-tune your business"
];

const paymentFeatures = [
  "Accept orders 24/7 with online checkout",
  "Offer safe, secure & reliable online payment options to your customers",
  "No follow-ups for deposit or balance payments",
  "Encourage customers to show appreciation - you keep 100% of tips",
  "Fast, secure, reliable ACH transfer to your bank account"
];

export default function JoinAsBaker(props) {
  const cta = (
    <Box my={4} textAlign="center">
      <Button
        size="large"
        variant="contained"
        component={RouterLink}
        to={`/book-demo`}
        style={{ minHeight: "60px", minWidth: "220px", fontSize: "20px" }}
      >
        Book a Demo
      </Button>
    </Box>
  );

  return (
    <Box>
      <div id="join-as-baker-bg">
        <Container maxWidth="sm">
          <Box sx={{ color: "#DEDEDE" }}>
            <Typography component="h1" sx={{ fontSize: "3rem", fontWeight: "700", textAlign: "center", lineHeight: 1.25 }}>
              Grow your Bakery Business
            </Typography>
            <Box my={2}>
              <Typography style={{ fontSize: "1.5em", color: "#fff", textAlign: "center" }}>
                Streamline all aspects of your bakery, from managing leads & orders to payments & kitchen operations - all in one place
              </Typography>
            </Box>
            {cta}
          </Box>
        </Container>
      </div>
      <div style={{ backgroundColor: "#fff", paddingTop: "6rem", paddingBottom: "6rem" }}>
        <Container maxWidth="lg">
          <Box align="center" pb={2}>
            <Box mb={8}>
              <Typography variant="h2" gutterBottom>
                BakeStreet Can Help!
              </Typography>
              <Typography variant="subtitle1">
                We're a platform tailored to helping you run your custom cake business in a modern & efficient way.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box mb={2}>
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fprofile.svg?alt=media&token=32487ece-7693-42c0-80a1-fbaca59b90fe"
                    style={{ height: "125px" }}
                  ></img>
                </Box>
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  Custom website
                </Typography>
                <Typography variant="body1">Showcase your talent, attract more customers & grow your business.</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box mb={2}>
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fops.svg?alt=media&token=548ed00e-2568-48a1-a6ac-b353dd85b4ad"
                    style={{ height: "125px" }}
                  ></img>
                </Box>
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  Productivity tools
                </Typography>
                <Typography variant="body1">We simplify everything from sending quotes to managing orders & kitchen operations.</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box mb={2}>
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fpay.svg?alt=media&token=f623664d-001c-4bcf-83ee-8d9f0d05955a"
                    style={{ height: "125px" }}
                  ></img>
                </Box>
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  Easy payments
                </Typography>
                <Typography variant="body1">Simplify payments for you & your customers by accepting online payments.</Typography>
              </Grid>
            </Grid>
          </Box>
          {cta}
        </Container>
      </div>
      {/*
      <Box style={{ backgroundColor: "#fafafa" }}>
        <Container id="profile" maxWidth="md">
          <Box py={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fprofile_shot.png?alt=media&token=899d8d94-2375-4e0f-871c-98e01c7c742e"
                  style={{ width: "100%" }}
                ></img>
              </Grid>
              <Grid item container direction="column" display="flex" justifyContent="center" xs={12} sm={8}>
                <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                  Free business website
                </Typography>
                {profileFeatures.map((t, i) => {
                  return (
                    <Typography variant="subtitle1" key={i}>
                      <CheckCircleOutlineOutlinedIcon style={{ position: "relative", top: "5px" }} />
                      &nbsp;&nbsp;{t}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#fff" }}>
        <Container id="tools" maxWidth="md">
          <Box py={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} id="flip-one">
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fget-quote-2.png?alt=media&token=3d08051f-563a-43a6-b80e-c4ab31af77e9"
                  style={{ width: "100%" }}
                ></img>
              </Grid>
              <Grid item container direction="column" display="flex" justifyContent="center" xs={12} sm={8} id="flip-two">
                <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                  Productivity tools
                </Typography>
                {productivityFeatures.map((t, i) => {
                  return (
                    <Typography variant="subtitle1" key={i}>
                      <CheckCircleOutlineOutlinedIcon style={{ position: "relative", top: "5px" }} />
                      &nbsp;&nbsp;{t}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#fafafa" }}>
        <Container id="payment" maxWidth="md">
          <Box py={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Fjoin%2Fcheckout.png?alt=media&token=e4a9212a-e830-4743-83bd-21fb5b637c78"
                  style={{ width: "100%" }}
                ></img>
              </Grid>
              <Grid item container direction="column" display="flex" justifyContent="center" xs={12} sm={8}>
                <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                  Easy Payments
                </Typography>
                {paymentFeatures.map((t, i) => {
                  return (
                    <Typography variant="subtitle1" key={i}>
                      <CheckCircleOutlineOutlinedIcon style={{ position: "relative", top: "5px" }} />
                      &nbsp;&nbsp;{t}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
              </Box> */}
      <Box py={10} style={{ backgroundColor: "#f7f7f7" }} align="center">
        <Box mb={4}>
          <Typography variant="h2" gutterBottom>
            Trusted by Top Bakeries
          </Typography>
          <Typography variant="subtitle1">to grow & manage their business</Typography>
        </Box>
        <Container maxWidth="sm">
          <Box align="center" py={3}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                {testimonials.map((t, i) => {
                  return (
                    <Box mb={4} key={i}>
                      <Testimonial data={t} />
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
            {cta}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
