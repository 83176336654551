import React, { useState } from "react";
import { Box, Button, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { Autocomplete } from "@mui/material";
import { TIME_WINDOWS } from "../../common/constants";

export default function PostCheckoutEntry(props) {
  const navigate = useNavigate();
  const { order, flavors, fillings } = props;

  const [flavor, setFlavor] = useState("");
  const [filling, setFilling] = useState("");
  const [inscription, setInscription] = useState("");

  const [time, setTime] = useState("11am - Noon");

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      flavor: flavor && flavor.name,
      filling: filling && filling.name,
      inscription,
      time
    };

    bkstApi.put(`/order/${order.id}/post-checkout`, payload).then((res) => {
      navigate(`/order/${order.id}`);
    });
  };

  return (
    <Paper>
      <Box px={2} py={3}>
        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            Add your preferences
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Your cake artist will review your preferences & may contact you if there are any questions
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                value={flavor}
                onChange={(e, v) => setFlavor(v)}
                onInputChange={(e, val) => setFlavor({ name: val })}
                options={flavors ? Object.values(flavors).sort((o1, o2) => (o1.name > o2.name ? 1 : -1)) : []}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.name} {option.type === "premium" && <Chip size="small" label={`premium`} />}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Flavor(s)"
                    helperText="Flavor of cake layers. Cake artist will confirm availability. Premium flavors may cost extra."
                  />
                )}
                disableClearable
                freeSolo
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={filling}
                onChange={(e, v) => setFilling(v)}
                onInputChange={(e, val) => setFilling({ name: val })}
                options={fillings ? Object.values(fillings).sort((o1, o2) => (o1.name > o2.name ? 1 : -1)) : []}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.name} {option.type === "premium" && <Chip size="small" label={`premium`} />}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filling(s)"
                    helperText="Filling goes between cake layers. Cake artist will confirm availability. Premium filling may cost extra."
                  />
                )}
                filterSelectedOptions
                disableClearable
                freeSolo
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="time-label">Preferred {order.fulfillment.type} time</InputLabel>
                <Select
                  value={time}
                  labelId="time-label"
                  label={`Preferred ${order.fulfillment.type} time`}
                  id="time"
                  name="time"
                  onChange={(e) => setTime(e.target.value)}
                >
                  {TIME_WINDOWS.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Cake artist will confirm availability</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="inscription"
                value={inscription}
                label="Inscription"
                name="inscription"
                variant="outlined"
                fullWidth
                onChange={(e) => setInscription(e.target.value)}
                helperText="Name: Emily. Age: 3"
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Button href={`/order/${props.order.id}`} variant="text" color="secondary" style={{ marginRight: "1rem" }}>
                Skip
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
}
