import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography } from "@mui/material";
import { HOW_IT_WORKS, STORAGE_BUCKET } from "../../common/constants";
import CloseIcon from "@mui/icons-material/Close";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const navBtnStyle = {
  position: "absolute",
  zIndex: 2,
  top: "25%",
  transform: "translateY(-50%)",
  color: "#000",
  backgroundColor: "#fff",
  borderColor: "#ebebeb",
  opacity: ".7",
  padding: "10px"
};

const navBtnPrevStyle = Object.assign({ left: "8px" }, navBtnStyle);
const navBtnNextStyle = Object.assign({ right: "8px" }, navBtnStyle);

export default function HowItWorksPopup(props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    props.addEvent && props.addEvent("how");
  };

  return (
    <Box mt={.5}>
      <Link onClick={handleClick} style={{ cursor: "pointer" }} underline="hover">
        How it works?
      </Link>
      <Dialog open={open} onClose={() => setOpen(false)} scroll="paper" fullWidth={true} maxWidth="sm">
        <DialogTitle style={{ margin: "0" }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>How it works?</Box>
            <Box>
              <IconButton size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers={false} style={{ backgroundColor: "#e3d8de", padding: "0" }}>
          <Box my={4}>
            <Carousel
              interval={5000}
              infiniteLoop={true}
              autoPlay={true}
              transitionTime={1000}
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              showArrows={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    style={navBtnPrevStyle}
                    size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    style={navBtnNextStyle}
                    size="large">
                    <ChevronRightIcon />
                  </IconButton>
                )
              }
            >
              {HOW_IT_WORKS.map((o, idx) => {
                return (
                  <Box key={idx} px={3} pb={4}>
                    <img alt="" height="100" src={`${STORAGE_BUCKET}/${o.img}`} />
                    <Box my={2}>
                      <Typography variant="h5" gutterBottom>
                        {o.title}
                      </Typography>
                      <Typography variant="body1">{o.desc}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
