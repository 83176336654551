import { Box, Link, Paper, Typography } from "@mui/material";
import { STORAGE_BUCKET } from "../../common/constants";

export default function Option(props) {
  return (
    <Paper style={{ color: "#FFF", backgroundColor: "#78374a", display: "inline-block", margin: "5px", minWidth: "130px", minHeight: "120px" }}>
      <Link
        color="inherit"
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
        underline="hover">
        <Box px={2} pt={3} pb={2} align="center">
          <img src={`${STORAGE_BUCKET}/img/icon/${props.icon}`} style={{ height: "60px" }} />
          <Typography variant="overline" display="block">
            {props.title}
          </Typography>
        </Box>
      </Link>
    </Paper>
  );
}
