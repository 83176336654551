import React from "react";
import { Box, Chip, Typography } from "@mui/material";

export default function QuoteDeliveryFee(props) {
  if (props.option === "free") {
    return (
      <Box>
        <Chip size="small" label="free delivery" style={{ backgroundColor: "#00b894", color: "#FFF" }} />
      </Box>
    );
  } else if (props.option === "fee") {
    return (
      <Box>
        <Typography variant="caption">free pickup / ${Math.round(+props.fee)} delivery</Typography>
      </Box>
    );
  } else {
    return (
      <Box>
        <Typography variant="caption">pickup only</Typography>
      </Box>
    );
  }
}
