import React, { useEffect } from "react";
import bkstApi from "../api";
import { useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";

export default function Redirect(props) {
  const { cat, id } = useParams();

  useEffect(() => {
    bkstApi(`/redirect/${cat}/${id}`)
      .then((res) => {
        window.location.href = res.data.to;
      });
  }, []);

  return <LinearProgress/>;
}
