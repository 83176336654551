import React from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

export default function BDatePicker(props) {
  const onChange = (value) => {
    if (value) {
      props.onChange(moment(value).format("YYYY-MM-DD"));
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        {...props}
        slotProps={{ toolbar: { hidden: true } }}
        value={props.value ? moment(props.value).toDate() : null}
        format="MMM d, y"
        closeOnSelect
        onChange={onChange}
      />
    </LocalizationProvider>
  );
}
