import React from "react";
import { Box, Container, Typography } from "@mui/material";
import CustomerFaq from "./CustomerFaq";

export default function Faq(props) {

  return (
    <Box mb={1} mt={5}>
      <Container maxWidth="md">
        <Box align="center" pb={2}>
          <Typography variant="h1">FAQs</Typography>
        </Box>
        <Box pb={7}>
          <CustomerFaq />
        </Box>
      </Container>
    </Box>
  );
}
