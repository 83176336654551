import React, { useState, useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import bkstApi from "../../api";

const navBtnStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  transform: "translateY(-50%)",
  color: "#000",
  backgroundColor: "#fff",
  borderColor: "#ebebeb",
  opacity: ".7",
  padding: "10px"
};

const navBtnPrevStyle = Object.assign({ left: "8px" }, navBtnStyle);
const navBtnNextStyle = Object.assign({ right: "8px" }, navBtnStyle);

export default function BakerPhotosButton(props) {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [open, setOpen] = useState(false);
  const [photos, setPhotos] = useState();

  useEffect(() => {
    if (open) {
      props.addEvent && props.addEvent("phc");

      if (!photos) {
        bkstApi(`/baker/${props.bakerId}/photos?src=${props.src}`).then((res) => {
          setPhotos(res.data.photos);
        });
      }
    }
  }, [open]);

  const close = () => {
    setSelectedIdx(0);
    setOpen(false);
  };

  return (
    <Box id="images" style={{ cursor: "pointer" }} textAlign="center">
      <Button size="small" variant="text" color="primary" onClick={() => setOpen(true)}>
        Photos
      </Button>
      <Dialog fullScreen open={open} onClose={close}>
        <DialogTitle style={{ backgroundColor: "#000", color: "#fff", margin: "0", border: "0" }}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              {props.name}'s Photos
            </Grid>
            <Grid item xs={4} align="right">
              <Button variant="outlined" color="inherit" size="small" onClick={close}>
                <CloseIcon /> CLOSE
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center" }}>
          {!photos && (
            <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Box mt={4} textAlign="center">
            <Carousel
              selectedItem={selectedIdx}
              transitionTime={1000}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    style={navBtnPrevStyle}
                    size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    style={navBtnNextStyle}
                    size="large">
                    <ChevronRightIcon />
                  </IconButton>
                )
              }
            >
              {photos &&
                photos.map((pic, idx) => {
                  return (
                    <Box key={`d${idx}`} style={{ color: "#CCC" }}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <img src={pic} style={{ height: "80vh", objectFit: "contain" }} />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
            </Carousel>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
