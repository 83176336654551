import React, { useContext } from "react";
import { Box, Drawer, Hidden } from "@mui/material";
import { UserContext } from "../../context/UserContext";

const drawerWidth = 240;

export default function ResponsiveDrawer(props) {
  const { user, setUser } = useContext(UserContext);

  const container = window !== undefined ? () => document.body : undefined;

    const drawer = <Box />

  if (user) {
    return (
      <nav aria-label="main-menu">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"left"}
            open={props.open}
            onClose={props.toggle}
            onClick={props.toggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
              "& a": { color: "inherit" }
            }}
          ></Drawer>
        </Hidden>
      </nav>
    );
  } else {
    return <Box />;
  }
}
