import React from "react";
import { Grid, Typography } from "@mui/material";

export default function IconText(props) {
  const Icon = props.icon;
  const iconSize = props.iconSize || "1.2rem";
  const fontSize = props.fontSize || ".75rem";

  return (
    <Grid container style={props.style || { flexWrap: "noWrap" }}>
      <Grid item>
        <Icon style={{ fontSize: iconSize, marginRight: ".4rem" }} />
      </Grid>
      <Grid item>
        <Typography variant={props.variant || "overline"} style={props.variant ? {} : { fontSize }}>
          {props.text}
        </Typography>
      </Grid>
    </Grid>
  );
}
