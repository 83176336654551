import React, { useState } from "react";
import { Badge, Box, Chip, IconButton, InputAdornment, TextField } from "@mui/material";
import bkstApi from "../../api";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachPhoto from "./AttachPhoto";

export default function NewMessage(props) {
  const [msg, setMsg] = useState("");
  const [pics, setPics] = useState([]);

  const [processing, setProcessing] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!processing){
      setProcessing(true);
      const payload = { msg, pics, ts: Date.now(), uid: props.uid };

      bkstApi.post(props.postUrl, payload).then((res) => {
        setProcessing(false);
      });

      setMsg("");
      setPics([]);
      props.handleNewMsg(payload);
    }
    e.target && e.target.blur();
  };

  const onEnter = (e) => {
    if (e.key === "Enter" && msg.trim().length > 0) {
      handleSubmit(e);
    }
  };

  const removePhoto = (idx) => {
    let arr = [...pics];
    arr.splice(idx, 1);
    setPics(arr);
  };

  return (
    <Box px={1} py={1}>
      <form onSubmit={handleSubmit}>
        <Box display="flex">
          <AttachPhoto id="attach" handleAttachments={(images) => setPics(images)} folder={props.uploadFolder} />
          <TextField
            id="msg"
            value={msg}
            name="msg"
            variant="outlined"
            multiline
            rows={msg.length / 40 > 4 ? 4 : msg.length / 40 + 1}
            fullWidth
            placeholder="Type a message"
            onChange={(e) => setMsg(e.target.value)}
            onKeyDown={onEnter}
            inputProps={{
              "data-hj-allow": ""
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={msg.length === 0 && pics.length === 0}
                    size="large">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </form>
      {pics && pics.length > 0 && (
        <Box
          align="center"
          style={{ backgroundColor: "#F7F7F7", border: "1px dashed #DDD", paddingTop: "20px", paddingBottom: "5px", marginLeft: "30px" }}
        >
          {pics.map((pic, idx) => {
            return (
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                badgeContent={
                  <IconButton onClick={() => removePhoto(idx)} size="small">
                    <CancelIcon />
                  </IconButton>
                }
              >
                <img key={idx} src={pic} style={{ width: "50px" }} />
              </Badge>
            );
          })}
        </Box>
      )}
      {props.templates && (
        <Box style={{ marginLeft: "30px" }}>
          {props.templates.map((t) => (
            <Chip key={t.name} size="small" label={t.name} component="a" onClick={() => setMsg(t.text)} clickable />
          ))}{" "}
        </Box>
      )}
    </Box>
  );
}
