import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Container, FormControl, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
export default function OrderReviewNPS(props) {
  const { orderId } = useParams();
  const [score, setScore] = useState("");
  const [text, setText] = useState("");

  const onSubmit = () => {
    const payload = {
      orderId,
      score,
      text,
    };
    bkstApi.post(`/order/${orderId}/review/nps`, payload).then((res) => {
      props.setStatus("done");
    });
  };

  return (
    <Container maxWidth="sm">
      <Box my={7}>
        <Paper elevation={0} style={{ marginBottom: "1rem" }}>
          <Box px={2} py={4} align="center">
            <Typography variant="h4" gutterBottom>
              Final Question!
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              How likely are you to recommend BakeStreet to a friend?
            </Typography>
            <Box py={4} align="center">
              <ToggleButtonGroup value={score} onChange={(e, v) => setScore(v)} exclusive>
                <ToggleButton color="primary" value="1">
                  1
                </ToggleButton>
                <ToggleButton color="primary" value="2">
                  2
                </ToggleButton>
                <ToggleButton color="primary" value="3">
                  3
                </ToggleButton>
                <ToggleButton color="primary" value="4">
                  4
                </ToggleButton>
                <ToggleButton color="primary" value="5">
                  5
                </ToggleButton>
                <ToggleButton color="primary" value="6">
                  6
                </ToggleButton>
                <ToggleButton color="primary" value="7">
                  7
                </ToggleButton>
                <ToggleButton color="primary" value="8">
                  8
                </ToggleButton>
                <ToggleButton color="primary" value="9">
                  9
                </ToggleButton>
                <ToggleButton color="primary" value="10">
                  10
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {score && score < 10 && (
              <Box mb={3}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={text}
                    variant="outlined"
                    fullWidth
                    id="text"
                    label="What can we do better? (optional)"
                    name="text"
                    multiline
                    rows={2}
                    onChange={(e) => setText(e.target.value)}
                    inputProps={{
                      "data-hj-allow": "",
                    }}
                  />
                </FormControl>
              </Box>
            )}
            <Box align="right">
              <Button variant="contained" color="primary" onClick={onSubmit}>
                SUBMIT
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
