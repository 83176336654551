import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import PostCheckoutEntry from "../component/PostCheckoutEntry";
import { IMG_CELEBRATE } from "../../common/constants";

export default function PostCheckout(props) {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();
  const [flavors, setFlavors] = useState();
  const [fillings, setFillings] = useState();

  useEffect(() => {
    bkstApi(`/post-checkout/${orderId}`).then((res) => {
      setOrder(res.data.order);
      setFlavors(res.data.flavors);
      setFillings(res.data.fillings);
      setLoading(false);
      document.title = res.data.order.details.title;
    });
  }, [orderId]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box p={1} mb={1} align="center">
          <img alt="" style={{ height: "60px" }} src={IMG_CELEBRATE} />
          <Typography variant="subtitle1" gutterBottom>
            Thanks for ordering, {order.customer.name}
          </Typography>
          {order.src === "mkt" && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Your cake artist will review your order shortly & you'll receive an order confirmation within 1-2 hours. Your payment is secure with
              BakeStreet.
            </Typography>
          )}
        </Box>
        <PostCheckoutEntry order={order} flavors={flavors} fillings={fillings} />
      </Container>
    );
  }
}
