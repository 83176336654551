import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Chip, Grid, LinearProgress, Link, Paper, Typography } from "@mui/material";
import bkstApi from "../../../api";
import Product from "../../../common/Product";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import TagSearchBox from "./TagSearchBox";

export default function SearchDesigns(props) {
  const ref = useRef(null);
  let location = useLocation();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState("");
  const [filterTags, setfilterTags] = useState([]);
  const [tags, setTags] = useState(props.tags);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.hash.startsWith("#search/")) {
      const token = location.hash.split("/")[1];
      setKeyword(token.replace("-", " "));
    }
  }, [location.hash]);

  useEffect(() => {
    if (keyword) {
      callApi(keyword);
    }
  }, [keyword]);

  const search = (keyword) => {
    navigate(`#search/${keyword.replace(/\s/g, "-")}`);
  };

  const callApi = (query) => {
    setLoading(true);
    setResults([]);

    bkstApi(`/photo?q=${query}&type=cake&occasion=${props.occasion}`).then((res) => {
      setResults(res.data.results);
      setfilterTags(res.data.tags);
      setLoading(false);
      //ref.current.scrollIntoView();
    });
  };

  const tagChips = (
    <Box my={4}>
      {tags &&
        tags.map((o, idx) => {
          return (
            <Chip
              key={idx}
              size="medium"
              color="primary"
              variant="outlined"
              label={o}
              clickable
              component={Link}
              href={`#search/${o.replace(/\s/g, "-")}`}
            />
          );
        })}
    </Box>
  );

  const searchForm = (
    <Box my={4}>
      <TagSearchBox onSubmit={search} />
    </Box>
  );

  return (
    <Paper>
      {loading && <LinearProgress />}
      <Box pt={4} px={2} ref={ref}></Box>
      {results.length > 0 && (
        <Box>
          <Box>
            <Typography variant="h4" gutterBottom>
              Here we go!
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Top designs for {keyword}
            </Typography>
          </Box>
          <Box px={2} pt={3} pb={4} align="center">
            {/* <Alert icon={<EmojiObjectsOutlinedIcon />} severity="error" style={{ position: "sticky", top: "0", zIndex: "10" }}>
              TIP: You can customize the colors, characters, tiers, etc for any design.
      </Alert> */}
            {filterTags && filterTags.length > 0 && (
              <Box align="left">
                <Typography variant="caption" color="textSecondary">
                  Filter by
                </Typography>
                {filterTags.map((o, idx) => {
                  return (
                    <Chip key={idx} size="medium" color="primary" variant="outlined" label={o} clickable component={Link} href={`#search/${o}`} />
                  );
                })}
              </Box>
            )}
            <Box my={4}>
              <Grid container spacing={4}>
                {results.map((value, index) => {
                  return (
                    <Grid item xs={12} sm={4} key={index} align="center">
                      <Product img={value.pic}>
                        <Button size="large" variant="contained" fullWidth onClick={() => props.onSubmit(keyword, [value.pic])} disableElevation>
                        Get Quote
                        </Button>
                      </Product>
                      <Box>
                          <Typography color="textSecondary" variant="caption" display="block" gutterBottom>
                            You can customize color, decor, tiers...
                          </Typography>
                        </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Box mt={5}>
                <Paper elevation={0} style={{ border: "4px solid #DDD" }}>
                  <Box px={2} py={5}>
                    <Typography variant="h4" gutterBottom>
                      Didn't like any design?
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      Try searching for a related theme or{" "}
                      <Link href="#upload" underline="always">
                        upload your design
                      </Link>
                    </Typography>
                    {tagChips}
                    {searchForm}
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
