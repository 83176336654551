import React, { useState } from "react";
import { Backdrop, Badge, Box, Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { placeholderImg } from "../../common/constants";
import { upload } from "../../firebase";

const navBtnStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  transform: "translateY(-50%)",
  color: "#000",
  backgroundColor: "#fff",
  borderColor: "#ebebeb",
  opacity: ".7",
  padding: "0"
};

const navBtnPrevStyle = Object.assign({ left: "8px" }, navBtnStyle);
const navBtnNextStyle = Object.assign({ right: "8px" }, navBtnStyle);

export default function Images(props) {
  const [pics, setPics] = useState(props.pics || []);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [open, setOpen] = useState(false);
  const [working, setWorking] = useState(false);

  let style = { width: "100%", aspectRatio: "1", objectFit: "cover" };

  const close = () => {
    setSelectedIdx(0);
    setOpen(false);
  };

  const handleUpload = async (event, folder) => {
    if (props.limit) {
      const total = pics.length + event.target.files.length;
      if (total > props.limit) {
        window.alert(`Can't upload more than ${props.limit} photos`);
        return;
      }
    }
    let tasks = [];
    setWorking(true);

    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // disable heic and heif
      if (file.name.endsWith(".heic") || file.name.endsWith(".heif")) {
        setWorking(false);
        window.alert(`Unsupported file format. Please upload jpeg or png file.`);
        return;
      }

      tasks.push(
        new Promise(async (resolve, reject) => {
          upload(file, folder).then((snapshot) => {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              resolve(downloadURL);
            });
          });
        })
      );
    }

    const imageUrls = await Promise.all(tasks);
    const arr = pics.concat(imageUrls);

    setPics(arr);
    props.onChange(arr);

    setWorking(false);
  };

  const remove = (idx) => {
    let arr = [...pics];
    arr.splice(idx, 1);
    setPics(arr);

    props.onChange(arr);
    //close();
  };

  const inputComponent = (
    <input accept="image/*" id={props.id} type="file" multiple onChange={(e) => handleUpload(e, props.folder)} style={{ display: "none" }} />
  );

  const backdrop = (
    <Backdrop open={working} style={{ zIndex: "1000", color: "#fff" }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const uploadPlaceholder = (
    <Link color="textSecondary" component="label" style={{ textAlign: "center", cursor: "pointer" }} underline="hover">
      {backdrop}
      <img src={placeholderImg} style={style} />
      <Typography variant="caption" display="block" sx={{ lineHeight: "1" }}>
        UPLOAD
      </Typography>
      {inputComponent}
    </Link>
  );

  const uploadButton = (
    <Button size="small" variant="contained" disableElevation component="label">
      Add Photo
      {inputComponent}
    </Button>
  );

  let header = (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <Button variant="outlined" color="inherit" size="small" onClick={close}>
          <CloseIcon /> CLOSE
        </Button>
      </Grid>
      <Grid item xs={6} align="center">
        <Typography variant="caption">{`${pics.length} photos`}</Typography>
      </Grid>
      <Grid item xs={3} align="right">
        {props.edit && uploadButton}
      </Grid>
    </Grid>
  );

  return (
    <Badge badgeContent={pics.length > 1 ? pics.length : 0} color="info">
      <Box id="images" style={{ cursor: "pointer", backgroundColor: "#ECECEC", borderRadius: "3px" }} align="center">
        {props.edit && pics.length === 0 ? (
          uploadPlaceholder
        ) : (
          <Carousel
            interval={5000}
            transitionTime={1000}
            showArrows={props.showArrows || false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            onClickItem={() => setOpen(true)}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnPrevStyle}>
                  <ChevronLeftIcon />
                </IconButton>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnNextStyle}>
                  <ChevronRightIcon />
                </IconButton>
              )
            }
          >
            {(pics && pics.length > 0 ? pics : [placeholderImg]).map((pic, idx) => {
              return <img alt="" src={pic} key={idx} style={style} />;
            })}
          </Carousel>
        )}
        <Dialog fullScreen open={open} onClose={close}>
          <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center" }}>
            {header}
            {backdrop}
            <Box mt={2} align="center">
              <Carousel
                selectedItem={selectedIdx}
                transitionTime={1000}
                showIndicators={false}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton onClick={onClickHandler} title={label} style={navBtnPrevStyle} size="large">
                      <ChevronLeftIcon />
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton onClick={onClickHandler} title={label} style={navBtnNextStyle} size="large">
                      <ChevronRightIcon />
                    </IconButton>
                  )
                }
              >
                {pics.map((pic, idx) => {
                  return (
                    <Box key={`d${idx}`} style={{ color: "#CCC" }}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <img src={pic} style={{ height: "80vh", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12}>
                          {(props.edit || props.allowDownload) && (
                            <Link underline="always" color="inherit" component="a" href={pic} download target="_blank">
                              download
                            </Link>
                          )}
                          {props.edit && (
                            <Link underline="always" color="inherit" onClick={() => remove(idx)} style={{ cursor: "pointer", marginLeft: "1rem" }}>
                              remove
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Carousel>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Badge>
  );
}
