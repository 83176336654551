import React, { useEffect, useRef } from "react";
import { Box, Container, Typography } from "@mui/material";
import { REFUND_POLICY } from "../constants";

export default function RefundPolicy(props) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  }, []);

  return (
    <Container maxWidth="sm" align="left" ref={ref}>
      <Box py={5} px={1}>
        <Typography variant="h1" color="textPrimary">
          Refund Policy
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Last updated: Jan 23, 2022
        </Typography>
        <Box my={5}>
          <Typography style={{ textAlign: "justify" }}>{REFUND_POLICY}</Typography>
        </Box>
      </Box>
    </Container>
  );
}
