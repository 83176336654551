import { useState, useEffect } from "react";
import { Box, Button, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Images from "../../../request/components/Images";

export default function CakeDetails(props) {
  const [showDietary, setShowDietary] = useState(false);

  const [tweak, setTweak] = useState("");
  const [details, setDetails] = useState("");
  const [dietary, setDietary] = useState("");

  useEffect(() => {
    if (tweak === "no") {
      setDetails("");
    }
  }, [tweak]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ dietary, details: `${dietary} ${details}` });
  };

  return (
    <Paper>
      <Box px={2} py={4} align="center">
        <Box>
          <Grid container>
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={6} xs={12}>
              <Box mb={2}>
                <Typography variant="h4" gutterBottom>
                  Customize your Cake
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  Custom cakes are made to order. You can tweak every element of the design to get exactly what you want. 
                </Typography>
              </Box>
              <form onSubmit={onSubmit}>
                <Grid container spacing={3} align="left">
                  <Grid item xs={12}>
                    <Images pics={props.pics} />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup name="tweak" value={tweak} onChange={(e) => setTweak(e.target.value)}>
                      <Box>
                        <FormControlLabel value="no" control={<Radio required={true} />} label={`I want this exact design`} />
                      </Box>
                      <Box>
                        <FormControlLabel value="yes" control={<Radio required={true} />} label={`I want to customize this design`} />
                        {/*tweak !== "yes" && (
                          <Typography variant="body2" color="textSecondary">
                            Change tiers, figurines, flowers, toppers...
                          </Typography>
                        )*/}
                      </Box>
                    </RadioGroup>
                    <Box>
                      {tweak === "yes" && (
                        <Box mt={2}>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              autoFocus={tweak === "yes"}
                              value={details}
                              variant="outlined"
                              fullWidth
                              id="details"
                              label=""
                              name="details"
                              multiline
                              rows={3}
                              onChange={(e) => setDetails(e.target.value)}
                              inputProps={{
                                "data-hj-allow": ""
                              }}
                              required
                              placeholder="Change tiers, add/remove toppers, decals, toys, figurines, flowers, etc."
                            />
                          </FormControl>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary" display="block">Optional</Typography>
                    {!showDietary && (
                      <Button variant="text" onClick={() => setShowDietary(true)}>Add dietary choices</Button>
                    )}
                    {showDietary && (
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          value={dietary}
                          variant="outlined"
                          id="dietary"
                          label="Dietary choices"
                          name="dietary"
                          fullWidth
                          onChange={(e) => setDietary(e.target.value)}
                          inputProps={{
                            "data-hj-allow": "",
                            maxLength: 15
                          }}
                          helperText="Nut-free, eggless, vegan, etc"
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
