import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import currency from "currency.js";
import { Box, Container, Grid, Link, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../../api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link as RouterLink } from "react-router-dom";
import StripePaymentElement from "../component/StripePaymentElement";
import StaticPage from "../../common/StaticPage";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function OrderInvoice(props) {
  const { orderId, invoiceId } = useParams();
  const [invoice, setInvoice] = useState();

  const stripeOptions = {
    clientSecret: invoice && invoice.clientSecret,
    appearance: {
      labels: "floating",
      variables: {
        colorPrimary: "#78374a",
        colorDanger: "#df1b41",
        borderRadius: "4px"
      }
    }
  };

  useEffect(() => {
    bkstApi(`/order/${orderId}/in/${invoiceId}`).then((res) => {
      setInvoice(res.data.invoice);
    });
  }, []);

  const validate = () => {
    return true;
  };

  if (invoice) {
    if (invoice.status === "void") {
      return (
        <StaticPage
          title="Canceled"
          desc="This payment request has been canceled. Please view your order page to see if any other payments are due."
          icon={BlockOutlinedIcon}
          href={`/order/${orderId}`}
          btnText="View Order"
        ></StaticPage>
      );
    } else if (invoice.status === "paid") {
      return (
        <StaticPage
          title="Paid"
          desc="You've already made this payment. Please view your order page to see if any other payments are due."
          icon={CheckCircleOutlineOutlinedIcon}
          href={`/order/${orderId}`}
          btnText="View Order"
        ></StaticPage>
      );
    } else {
      return (
        <Container maxWidth="sm">
          <Box py={5}>
            <Box mb={2}>
              <Typography variant="h4" gutterBottom>
                Payment due for order add-on
              </Typography>
              <Typography color="textSecondary">
                Your cake artist is requesting payment for an add-on to your{" "}
                <Link component={RouterLink} to={`/order/${orderId}`} underline="hover">
                  {invoice.title}
                </Link>{" "}
                order.
              </Typography>
            </Box>
            <Paper>
              <Box px={3} pt={2} pb={5}>
                <Box my={2}>
                  <Paper elevation={0} style={{ backgroundColor: "#F7F7F7", padding: "1rem" }}>
                    <Typography variant="subtitle1">{invoice.description}</Typography>
                  </Paper>
                </Box>
                <Box my={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography variant="caption" color="textSecondary">
                        Subtotal
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <Typography variant="caption" color="textSecondary">
                        {currency(invoice.amount.addOn).format()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography variant="caption" color="textSecondary">
                        Service fee
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <Typography variant="caption" color="textSecondary">
                        {currency(invoice.amount.fee).format()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography variant="overline">Total (USD)</Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <Typography variant="subtitle1">{currency(invoice.amount.total).format()}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Elements stripe={promise} options={stripeOptions}>
                  <StripePaymentElement
                    amount={currency(invoice.amount.total).format()}
                    validate={validate}
                    returnUrl={`${window.location.origin}/payment-status?order_id=${orderId}&invoice_id=${invoiceId}`}
                  />
                </Elements>
              </Box>
            </Paper>
          </Box>
        </Container>
      );
    }
  } else {
    return <LinearProgress />;
  }
}
