import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Chip, Container, Grid, Link, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../api";
import Product from "../common/Product";
import TagSearchBox from "../request/components/wizard/TagSearchBox";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

export default function Collection(props) {
  const navigate = useNavigate();
  const { handle } = useParams();

  const [title, setTitle] = useState();
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);

  const search = () => {
    bkstApi(`/collection/${handle}`)
      .then((res) => {
        setTitle(res.data.title);
        document.title = res.data.title;
        setProducts((arr) => [...products, ...res.data.products]);

        if (res.data.tags) {
          setTags(res.data.tags.sort());
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    search();
  }, [handle]);

  if (!title) {
    return <LinearProgress />;
  } else {
    const tagChips = (
      <Box my={4}>
        {tags &&
          tags.map((o, idx) => {
            return (
              <Chip
                key={idx}
                size="medium"
                color="primary"
                variant="outlined"
                label={o}
                clickable
                component={Link}
                href={`/get-quote/design#search/${o.replace(/\s/g, "-")}`}
              />
            );
          })}
      </Box>
    );

    const searchForm = (
      <Box my={4}>
        <TagSearchBox onSubmit={(value) => navigate(`/get-quote/design#search/${value.replace(/\s/g, "-")}`)} />
      </Box>
    );

    return (
      <Container maxWidth="md">
        <Box pt={5} align="center">
          <Typography variant="h1" component="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Select a design to get quotes from top cake artists in your area
          </Typography>
        </Box>
        <Box mt={5}>
          <Box align="center">
            {tags.length > 0 && (
              <Box my={2}>
                {tags.map((t, i) => {
                  return <Chip key={i} size="small" label={t} component={RouterLink} to={`/get-quote/design#search/${t}`} clickable />;
                })}
              </Box>
            )}
          </Box>
          <Grid container spacing={3}>
            {products.map((value, index) => {
              return (
                <Grid item xs={12} sm={4} key={index} align="center">
                  <Box>
                    <Product img={value.img}>
                      <Button
                        size="large"
                        variant="contained"
                        component={RouterLink}
                        to="/get-quote/customize"
                        state={{ req: { pics: [value.img] } }}
                        fullWidth
                        disableElevation
                      >
                        Get Quote
                      </Button>
                    </Product>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box mt={5} align="center">
          <Paper variant="outlined">
            <Box px={2} py={5}>
              <Typography variant="h4" gutterBottom>
                Didn't like any design?
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Try searching for a related theme or{" "}
                <Link href="/get-quote/design#upload" underline="always">
                  upload your design
                </Link>
              </Typography>
              {tagChips}
              {searchForm}
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
