import React, { useState, useEffect } from "react";
import { Box, Container, Grid, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import Review from "../../review/component/Review";

export default function RecentReviews(props) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi.get(`/recent-reviews`).then((res) => {
      setReviews(res.data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <Box>
          <Box my={6} align="center">
            <Typography variant="h2" gutterBottom>
              Customer Reviews
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Recent reviews for our talented cake artists
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {reviews.map((el) => {
              return (
                <Grid item sm={6} xs={12} key={el.id}>
                  <Review data={el} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    );
  }
}
