import React from "react";
import { Box, Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@mui/material";
import Review from "../../review/component/Review";

export default function BakerReviewsPopup(props) {
  return (
    <Dialog open={props.open} onClose={props.onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>{props.name}'s Reviews</DialogTitle>
      <DialogContent style={{ height: "90vh", padding: "5px" }}>
        <Box>
          {props.reviews.map((el) => {
            return (
              <Box mb={2} key={el.id}>
                <Review data={el} baker={props.baker} />
              </Box>
            );
          })}
          {!props.reviews ||
            (props.reviews.length === 0 && (
              <Box px={4} py={6} align="center">
                <Typography variant="subtitle1">No reviews on BakeStreet yet!</Typography>
              </Box>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
