import React from "react";
import { AppBar, Box, Button, Link, Toolbar } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { bakeStreetLogo } from "./common/constants";

function Header(props) {
  return (
    <div>
      <AppBar position="static" style={{ background: "#fff", boxShadow: "none", borderBottom: "1px solid #eee" }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
          <Box>
            <Link to={"/"} component={RouterLink} underline="hover">
              <img alt="logo" style={{ width: "110px" }} src={bakeStreetLogo}></img>
            </Link>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", flexGrow: "1", gap: "10px" }}>
            <Button color="secondary" to={"/marketplace"} component={RouterLink}>
              Marketplace
            </Button>
            <Button color="secondary" to={"/"} component="a" href="https://my.bakestreet.co/login">
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default Header;
