import moment from "moment";

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  var match = phoneNumber.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    return value;
  }
};

export function getQueryParam(queryStr, p) {
  const arr = queryStr.replace("?", "").split("&");
  for (const a of arr) {
    if (a.startsWith(`${p}=`)) {
      return decodeURIComponent(a.substring(p.length + 1));
    }
  }
  return undefined;
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function createSnippets(productType, shape, size, servings) {
  const data = [];
  if (!productType || productType === "Cake") {
    data.push(`${shape}, ${size} cake`);
    data.push(`${servings} servings`);
  } else {
    data.push(`${servings} ${productType}`);
  }

  return data;
}

export function getFeeFactor(plan) {
  if (plan === "starter") {
    // 3.5% payment fee + 3% success fee
    return 1.0696;
  } else if (plan === "professional") {
    // 3.5% payment fee + 2% success fee
    return 1.0583;
  } else if (plan === "premium") {
    // 3.5% payment fee + 1% success fee
    return 1.0472;
  } else {
    return 1;
  }
}

export function isV2SaaSPlan(plan) {
  return plan === "starter" || plan === "professional" || plan === "premium";
}

export function getDisplayName(firstName, lastName, name) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else {
    return name;
  }
}

export function getDefaultQuoteExpiration(eventDate) {
  if (eventDate) {
    const daysToEvent = moment(eventDate).diff(moment(), "days");
    if (daysToEvent < 3) {
      return moment(eventDate).endOf("day");
    } else if (daysToEvent < 7) {
      return moment().add(1, "days");
    } else if (daysToEvent < 21) {
      return moment().add(2, "days");
    } else if (daysToEvent < 45) {
      return moment().add(3, "days");
    } else {
      return moment().add(5, "days");
    }
  } else {
    return moment().add(1, "days");
  }
}

export function generateSizeSummary(variant) {
  let summary = "";

  if (variant) {
    if (variant.shape) {
      summary += `${variant.shape} • `;
    }
    if (variant.size) {
      summary += `${variant.size} • `;
    }
    if (variant.servings) {
      summary += `${variant.servings} servings`;
    }
  }

  return summary;
}

export function generateItemSnippets(item) {
  const data = [];

  if (item.variant) {
    if (item.variant.size) {
      let arr = [];
      if (item.variant.size.shape) {
        arr.push(item.variant.size.shape);
      }
      if (item.variant.size.size) {
        arr.push(item.variant.size.size);
      }
      if (item.variant.size.servings) {
        arr.push(`${item.variant.size.servings} servings`);
      }
      if (arr.length > 0) {
        data.push(`Size • ${arr.join(", ")}`);
      }
    }

    if (item.variant.frosting) {
      data.push(`Icing • ${item.variant.frosting.name}`);
    }
    if (item.variant.flavor) {
      const flavor = normalizeFlavor(item.variant.flavor);
      if (flavor.name) {
        data.push(`Flavor • ${flavor.name}`);
      }

      if (flavor.filling) {
        data.push(`Filling • ${flavor.filling}`);
      }
    }
    for (const [key, value] of Object.entries(item.variant)) {
      if (["size", "flavor", "frosting", "add-on"].includes(key)) {
        continue;
      } else {
        data.push(`${capitalize(key)} • ${value?.name}`);
      }
    }
    if (item.variant?.[`add-on`]) {
      const addOnValues = item.variant?.[`add-on`].map((o) => o.name).join(", ");
      data.push(`Add-Ons • ${addOnValues}`);
    }
  }

  if (item.customization) {
    if (item.customization.inscription && item.customization.inscription.text) {
      data.push(`Inscription: ${item.customization.inscription.text}`);
    }
  }

  if (item.tags) {
    data.push(...item.tags);
  }

  return data;
}

export function normalizeFlavor(value) {
  if (Array.isArray(value)) {
    return value[0];
  } else if (typeof value === "object") {
    return { name: value?.base?.name, filling: value?.filling?.name };
  } else {
    return "";
  }
}

