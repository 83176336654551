import axios from "axios";
import { auth } from "../firebase";

const bkstApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

bkstApi.interceptors.request.use(async (config) => {
  return new Promise(async (resolve, reject) => {
    auth.onAuthStateChanged(async function (user) {
      let idToken = "";
      if (user) {
        idToken = await user.getIdToken();
      }
      config.headers.Authorization = `Bearer ${idToken}`;
      resolve(config);
    });
  });
});

bkstApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401 || status === 403) {
      window.location.href = `/login?target=${window.location.pathname}`;
    } else if (status === 301) {
      window.location.replace(error.response.data.location);
    }

    return Promise.reject(error);
  }
);

export default bkstApi;
