import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, LinearProgress, Paper, Typography } from "@mui/material";
import QuoteItem from "../quote/QuoteItem";
import bkstApi from "../api";
import { getQueryParam } from "../common/util";
import RequestExpired from "./screens/RequestExpired";
import { STORAGE_BUCKET } from "../common/constants";
import RequestSummary from "./components/RequestSummary";

export default function CustomerQuotes() {
  const [request, setRequest] = useState();
  const { reqId } = useParams();

  useEffect(() => {
    bkstApi("/requests/" + reqId).then((res) => {
      document.title = res.data.title;
      setRequest(res.data);
    });
  }, [reqId]);

  if (!request) {
    return <LinearProgress />;
  } else if (request.status === "closed") {
    return <RequestExpired />;
  } else {
    let statusWidget = <Box />;
    let quotesWidget = <Box />;

    if (!request.quotes || request.quotes.length === 0) {
      statusWidget = (
        <Box>
          <Paper elevation={0} style={{ backgroundColor: "#FDECEA", color: "#83332D" }}>
            <Box my={2} px={2} py={4} align="left">
              <Typography variant="h2" gutterBottom>
                Sorry!
              </Typography>
              <Box mb={2}>
                <Typography variant="body1">
                  We're unable to find any cake artists that serve <u>{request.location}</u> within your <b>${request.budget}</b> budget.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  We currently serve the greater NYC area, including parts of NJ, CT. If you need a cake within our service area, please consider
                  raising your budget to get quotes.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      );
    } else {
      const quoted = request.quotes.filter((q) => ["active", "rbook", "expired"].includes(q.status));

      if (request.src === "mkt" && quoted.length === 0) {
        statusWidget = (
          <Paper elevation={0}>
            <Box my={2} px={2} pb={6} align="center">
              <img src={`${STORAGE_BUCKET}/images/page/home/transmit.svg`} style={{ maxHeight: "200px" }} />
              <Typography variant="h5" gutterBottom>
                Waiting for Quotes!
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Your quote request has been sent to <strong>{request.quotes.length}</strong> cake artists that serve <u>{request.location}</u>. You
                should get multiple quotes within a few hours. We'll notify you via text when your quotes come in.
              </Typography>
            </Box>
          </Paper>
        );
      }

      quotesWidget = (
        <Box>
          <Paper elevation={0}>
            <Box px={2} py={2} align="center">
              <Typography variant="subtitle1" gutterBottom>
                Book with confidence
              </Typography>
              <Typography variant="body1" color="textSecondary">
                BakeStreet is a top-rated marketplace for booking custom cakes from vetted cake artists in a secure & trusted way.
              </Typography>
              <Box mt={1}>
                <a target="_blank" href="https://www.trustpilot.com/review/bakestreet.co" style={{ textDecoration: "none" }}>
                  <img src={`${STORAGE_BUCKET}/img/h/TP.png`} style={{ height: "45px" }} />
                </a>
              </Box>
            </Box>
          </Paper>
          <Box mt={4} mb={1}>
            <Typography variant="body1" color="textSecondary">
              {`Your quote${request.src === "mkt" ? "s" : ""}`}
            </Typography>
          </Box>
          {request.quotes.map((value, index) => {
            return (
              <Box mb={6} key={index} id={`quote-${value.id}`}>
                <QuoteItem reqId={reqId} request={request} quote={value} experiment={request.experiment} />
              </Box>
            );
          })}
        </Box>
      );
    }

    return (
      <Container maxWidth="sm">
        <Box mt={4} mb={1}>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {`You requested ${request.src === "mkt" ? "quotes" : "a quote"} for`}
          </Typography>
        </Box>
        <RequestSummary request={request} />
        <Box my={4}>{statusWidget}</Box>
        {quotesWidget}
      </Container>
    );
  }
}
