import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { Facebook, Instagram } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

export default function Footer() {
  return (
    <Box pt={15} pb={5} align="center">
      <Box pb={2}>
        <Link color="secondary" style={{ padding: "1rem" }} href="mailto:support@bakestreet.co" underline="hover">
          Contact
        </Link>
        |
        <Link color="secondary" style={{ padding: "1rem" }} component={RouterLink} to="/privacy-policy" underline="hover">
          Privacy Policy
        </Link>
        |
        <Link color="secondary" style={{ padding: "1rem" }} component={RouterLink} to="/terms-of-service" underline="hover">
          Terms of Service
        </Link>
      </Box>
      {!window.location.pathname.startsWith("/shop/") && (
        <Box pb={2}>
          <Link color="inherit" style={{ padding: ".5rem" }} href="https://www.facebook.com/bakestreet.co" underline="hover">
            <Facebook />
          </Link>
          <Link color="inherit" style={{ padding: ".5rem" }} href="https://www.instagram.com/bakestreetapp" underline="hover">
            <Instagram />
          </Link>
        </Box>
      )}
      <Typography color="textSecondary" variant="caption">
        &copy; 2024 BakeStreet Inc.
      </Typography>
    </Box>
  );
}
