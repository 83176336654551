import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, Grid, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import ChatCanvas from "../../common/chat/ChatCanvas";

export default function RequestToBook(props) {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [sent, setSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { msg, pics: [], to: props.quote.baker.bizName };

    bkstApi.post(`/requests/${props.reqId}/quotes/${props.quote.id}/chat`, payload).then((res) => {});
    setSent(true);
  };

  const handleClose = () => {
    setSent(false);
    setMsg("");
    setOpen(false);
  };

  return (
    <Box display="inline">
      <Button variant="contained" color="primary" onClick={(e) => setOpen(true)}>
        Request To Book
      </Button>
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <Box p={2} style={{ backgroundColor: "#f7f7f7", lineHeight: "1" }}>
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item>
            <Typography>Send a Message</Typography>
            </Grid>
          </Grid>
        </Box>
        <DialogContent dividers>
          {!sent && (
            <Box>
              <Typography variant="body2">
                Message your cake artist to request a quote again
              </Typography>
              <ChatCanvas />
              <Box pt={2}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    id="msg"
                    value={msg}
                    name="msg"
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    autoFocus
                    placeholder="Type a message"
                    onChange={(e) => setMsg(e.target.value)}
                    helperText="Please note that their quote may change or they may no longer be available."
                  />
                  <Box pt={2} align="right">
                    <Button variant="text" onClick={handleClose} style={{ marginRight: "20px" }}>
                      Close
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Send
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          )}
          {sent && (
            <Box pt={1}>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Your request has been sent & we'll notify you via text and email when your cake artist responds.
              </Typography>
              <Box pt={1} align="right">
                <Button onClick={handleClose} variant="contained" color="primary">
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
